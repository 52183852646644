import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { set } from '@ember/object';
import { v4 as uuid } from 'uuid';
import Ember from 'ember';
import ComponentService from '../../services/component';
import OrderService from '../../services/order';
import { BehaviorSubject, debounceTime, map, Subscription } from 'rxjs';
import { Layout, UserRoleEnum } from '../../services/api-client';
import AuthService from '../../services/auth';
import { observes } from '@ember-decorators/object';
import LayoutService from '../../services/layout';

@tagName('')
export default class AppComponents extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;
  @service('order') orderService!: OrderService;
  @service('layout') layoutService!: LayoutService;
  @service() amplify: any;
  @service('component') componentService!: ComponentService;

  makroTypeOptions = ['Rechteck', 'Rundloch', 'Nietmutter', 'Gewinde', 'Gewindebolzen'];
  selectedType: string = 'Rechteck';

  @computed('selectedSize')
  get makroLengthOptions() {
    if (this.selectedSize == 'M5' || this.selectedSize == 'M6') {
      return ['8', '16', '30'];
    } else {
      return ['10', '16', '20', '25', '30'];
    }
  }

  @observes('selectedSize')
  selectedSizeChange() {
    if (!this.makroLengthOptions.includes(this.makroLength)) {
      this.set('makroLength', this.makroLengthOptions[0]);
    }
  }

  @observes('selectedType')
  selectedTypeChange() {
    if (this.selectedType !== 'Komponente') {
      this.set('selectedComponent', undefined);
    }
  }

  @computed('selectedType', 'selectedComponent')
  get selectedTypeID() {
    if (this.selectedType == 'Komponente') {
      return this.selectedComponent?.id;
    }
    if (this.selectedType == 'Rechteck') {
      return 240475;
    } else if (this.selectedType == 'Rundloch') {
      return 240473;
    } else if (this.selectedType == 'Nietmutter') {
      return 339147;
    } else if (this.selectedType == 'Gewinde') {
      return 339148;
    } else if (this.selectedType == 'Gewindebolzen') {
      return 339149;
    }
    return 240475;
  }

  getSelectedTypeByID(id: number) {
    if (id == 240475) {
      return 'Rechteck';
    } else if (id == 240473) {
      return 'Rundloch';
    } else if (id == 339147) {
      return 'Nietmutter';
    } else if (id == 339148) {
      return 'Gewinde';
    } else if (id == 339149) {
      return 'Gewindebolzen';
    }
    return 'Komponente';
  }

  @computed('selectedType')
  get isComponentType() {
    return this.selectedType == 'Komponente';
  }

  @computed('selectedType')
  get makroSizeOptions() {
    if (this.selectedType == 'Nietmutter') {
      return ['M4', 'M5', 'M6', 'M8'];
    } else if (this.selectedType == 'Gewinde') {
      return ['M3', 'M4', 'M5', 'M6'];
    } else {
      return ['M5', 'M6', 'M8'];
    }
  }
  selectedSize: string = 'M5';

  @computed('selectedSize')
  get makroDiameterFromSize() {
    if (this.selectedSize == 'M3') {
      return 3; // 2.5
    } else if (this.selectedSize == 'M4') {
      return 4; // 3.3
    } else if (this.selectedSize == 'M5') {
      return 5; // 4.2
    } else if (this.selectedSize == 'M6') {
      return 6; // 5
    } else if (this.selectedSize == 'M8') {
      return 7; // 6.8
    }

    return 0;
  }

  makroSizeFromDiameter(diameter: number) {
    if (diameter == 3) {
      return 'M3';
    } else if (diameter == 4) {
      return 'M4';
    } else if (diameter == 5) {
      return 'M5';
    } else if (diameter == 6) {
      return 'M6';
    } else if (diameter == 7) {
      return 'M8';
    }

    return 'M5';
  }

  activeFilter: string = '';
  searchString: string = '';

  width: number = 1000;
  height: number = 1000;

  componentsView = '1';

  makrobreite: number = 100;
  makrohoehe: number = 100;
  makrorundung: number = 0;
  makrodurchmesser: number = 30;

  // Advanced Mode
  selectedInstance: any = undefined;
  makroPosX?: number = undefined;
  makroPosY?: number = undefined;
  makroRotation: number = 0;
  makroWidth?: number = undefined;
  makroHeight?: number = undefined;
  makroLength: string = '16';
  makroRounding: number = 0;
  makroDiameter: number = 30;

  advancedError = '';

  isSubmitting = false;
  submittingTimeout = undefined;

  // CopyFunc
  makroSpaceX?: number = undefined;
  makroAmountX: number = 1;
  makroSpaceY?: number = undefined;
  makroAmountY: number = 1;

  makrobreitepreview: number = 100;
  makrohoehepreview: number = 100;
  makrorundungpreview: number = 0;
  makrodurchmesserpreview: number = 150;
  makrodurchmesserroundingpreview: number = 75;

  makrolanglochbreite: number = 50;
  makrolanglochdurchmesser: number = 50;

  selectedComponent: any = undefined;
  selectedComponents: any[] = [];

  // Layouts
  layoutName: string = '';
  showConfirmation: boolean = false;
  selectedDeletedLayout: Layout | undefined = undefined;

  recomputeBlocks: boolean = false;

  // New Feature for limited for 7 days upon first visit
  isNew = localStorage.getItem('FIMAB_NEWFEATURE_LOOKEDAT')
    ? new Date().getTime() - +(localStorage.getItem('FIMAB_NEWFEATURE_LOOKEDAT') ?? 0) < 1000 * 60 * 60 * 24 * 7
    : true;

  constructor() {
    super(...arguments);

    if (localStorage.getItem('FIMAB_NEWFEATURE_LOOKEDAT') == null) {
      localStorage.setItem('FIMAB_NEWFEATURE_LOOKEDAT', new Date().getTime().toString());
    }

    this.amplify.on('makro:dropped', this, 'toggleRecompute');
    this.amplify.on('3DView:activeSide', this, 'activeSide');

    if (this.FIMAB_CONFIGURATOR.isInitialized) {
      this.init2DView();
    } else {
      (window as any).FIMAB_CONFIGURATOR.init = () => this.init2DView();
    }
    this.setConfiguratorIsAdmin();
  }

  @observes('authService.currentUser', 'authService.isAuthenticated')
  setConfiguratorIsAdmin() {
    (window as any).FIMAB_CONFIGURATOR.isAdmin = this.isAdmin;
  }

  @action
  selectAdvancedComponent(component: any) {
    this.set('componentsView', '3');
    this.set('selectedComponent', component);
    this.set('selectedType', 'Komponente');
    setTimeout(() => {
      document.getElementById('x-input')?.focus();
    }, 0);
  }

  @action
  evalVal(val: any, key: string) {
    let evaluatedValue;
    try {
      // eval() ist eine einfache Möglichkeit mit arithmetrischen Ausdrücken umzugehen
      if (isNaN(Number(eval(String(val))))) return;
      evaluatedValue = Number(eval(String(val)));
    } catch (error) {
      // Fallback falls Ausdruck fehlerhaft
      evaluatedValue = Number(this.get(key));
    }

    this.set(key, evaluatedValue);
  }

  evalAll() {
    this.evalVal(this.makroPosX, 'makroPosX');
    this.evalVal(this.makroPosY, 'makroPosY');
    this.evalVal(this.makroRotation, 'makroRotation');
    this.evalVal(this.makroWidth, 'makroWidth');
    this.evalVal(this.makroHeight, 'makroHeight');
    this.evalVal(this.makroRounding, 'makroRounding');
    this.evalVal(this.makroSpaceX, 'makroSpaceX');
    this.evalVal(this.makroAmountX, 'makroAmountX');
    this.evalVal(this.makroSpaceY, 'makroSpaceY');
    this.evalVal(this.makroAmountY, 'makroAmountY');
  }

  @computed('selectedComponents')
  get selectedComponentsLength() {
    const allComps = this.FIMAB_CONFIGURATOR.getComponents();
    const selectedIds = this.selectedComponents.map((c: any) => c.id);
    return allComps.filter((c: any) => selectedIds.includes(c.id) || selectedIds.includes(c.parentId)).length;
  }

  //#region Layout Functions
  @action
  addLayout() {
    const allComponents = this.FIMAB_CONFIGURATOR.getComponents();
    // this.orderService.addLayout(this.get('model'), this.layoutName);
    const componentsToSave = this.selectedComponents.map((c) => {
      const copy = { ...c };
      delete copy.element;
      delete copy.contentElement;
      return copy;
    });

    for (const component of componentsToSave) {
      if (component.main) {
        componentsToSave.push(
          ...allComponents
            .filter((c: any) => c.parentId == component.id)
            .map((c: any) => {
              delete c.element;
              delete c.contentElement;
              return c;
            })
        );
      }
    }
    this.layoutService.addLayout(this.layoutName, componentsToSave);
  }

  @action
  deleteLayout(layout: Layout) {
    this.set('showConfirmation', true), this.set('selectedDeletedLayout', layout);
  }

  @action
  confirmDeleteLayout() {
    if (this.selectedDeletedLayout) {
      this.layoutService.deleteLayout(this.selectedDeletedLayout);
    }
    this.set('showConfirmation', false);
  }

  applyLayout(layout: Layout) {
    const components = (layout.components as any[]).map((c: any) => ({ ...c }));
    const idMap: { [oldId: string]: string } = {};

    // Create ID map for new IDS
    for (const component of components) {
      const id = String('c' + String(new Date().getTime()).substr(-5) + ((Math.random() * 10000) >> 0));
      idMap[component.id] = id;
    }

    const groupId = String('g' + String(new Date().getTime()).substr(-5) + ((Math.random() * 10000) >> 0));

    // Apply new Ids
    const newComponents = components.map((component) => {
      component.id = idMap[component.id];
      if (component.parentId) {
        component.parentId = idMap[component.parentId];
      }
      if (component.children) {
        component.children = component.children.map((childId: string) => idMap[childId]);
      }

      component.groupId = groupId;

      return component;
    });

    for (const comp of newComponents) {
      this.FIMAB_CONFIGURATOR.addComponentInstance(comp);
    }

    console.log(this.FIMAB_CONFIGURATOR.getComponents());
  }

  private configuratorSubscriptions: Subscription[] = [];
  init2DView() {
    this.configuratorSubscriptions.push(
      this.FIMAB_CONFIGURATOR.onEdit?.subscribe((visible: boolean) => {
        if (visible && this.isAdmin) {
          // this.showExtended();
        }
      }),
      this.FIMAB_CONFIGURATOR.onResizeEnd?.subscribe(() => this.saveOrder()),
      this.FIMAB_CONFIGURATOR.onAddInstance.pipe(debounceTime(300)).subscribe(() => this.saveOrder()),
      this.FIMAB_CONFIGURATOR.onSaveCabinet.pipe(debounceTime(300)).subscribe(() => this.saveOrder()),
      this.FIMAB_CONFIGURATOR.onDeleteInstance.pipe(debounceTime(300)).subscribe(() => this.saveOrder()),
      this.FIMAB_CONFIGURATOR.onDragEnd.pipe(debounceTime(300)).subscribe(() => this.saveOrder()),
      this.FIMAB_CONFIGURATOR.onResizeEnd.pipe(debounceTime(300)).subscribe(() => this.saveOrder())
    );

    this.configuratorSubscriptions.push(
      (this.FIMAB_CONFIGURATOR.onSelectChange as BehaviorSubject<any[]>)
        .pipe(
          debounceTime(100),
          map((s) => {
            this.set('selectedComponents', s || []);
            return s;
          }),
          map((s) => (s !== undefined ? s[0] : undefined))
        )
        .subscribe((selectedInstance) => {
          console.log(selectedInstance);

          if (this.isAdmin && selectedInstance && this.selectedInstance == undefined && this.componentsView !== '4') {
            this.set('componentsView', '3');
          }
          if (selectedInstance) {
            this.set('selectedType', this.getSelectedTypeByID(selectedInstance.fimabId));
            if (this.selectedType === 'Komponente') {
              this.set('selectedComponent', selectedInstance);
            } else if (
              this.selectedType === 'Nietmutter' ||
              this.selectedType === 'Gewinde' ||
              this.selectedType === 'Gewindebolzen'
            ) {
              this.set('selectedSize', this.makroSizeFromDiameter(selectedInstance.preciseDiameter));
              if (this.selectedType === 'Gewindebolzen') {
                this.set('makroLength', selectedInstance.makroLength);
              }
            }

            this.set('makroWidth', +selectedInstance.w);
            this.set('makroHeight', +selectedInstance.h);
            this.set('makroPosX', +selectedInstance.centerX);
            this.set('makroPosY', +selectedInstance.centerY);
            this.set('makroRounding', +selectedInstance.rounding);
            this.set('makroRotation', +selectedInstance.r);
            this.set('selectedInstance', selectedInstance);
            this.set('makroDiameter', +selectedInstance.w);

            if (selectedInstance.copy && selectedInstance.main) {
              if (selectedInstance.copy.makroSpaceX) {
                this.set('makroSpaceX', +selectedInstance.copy.makroSpaceX);
              } else {
                this.set('makroSpaceX', undefined);
              }
              if (selectedInstance.copy.makroSpaceY) {
                this.set('makroSpaceY', +selectedInstance.copy.makroSpaceY);
              } else {
                this.set('makroSpaceY', undefined);
              }
              this.set('makroAmountX', selectedInstance.copy.makroAmountX);
              this.set('makroAmountY', selectedInstance.copy.makroAmountY);
            } else {
              this.set('makroSpaceX', undefined);
              this.set('makroSpaceY', undefined);
              this.set('makroAmountX', 1);
              this.set('makroAmountY', 1);
            }
          } else {
            this.resetAdvancedForm();
          }
        })
    );
  }

  saveOrder() {
    this.orderService.saveOrder(this.get('model'));
  }

  willDestroyElement() {
    this.configuratorSubscriptions.forEach((subscription) => subscription.unsubscribe());
    this.amplify.off('makro:dropped', this, 'toggleRecompute');
    this.amplify.off('3DView:activeSide', this, 'activeSide');
  }

  /**
   *
   * @param size
   */
  activeSide(size: any) {
    this.set('width', size.Seitenbreite);
    this.set('height', size.Seitenhoehe);
  }

  @computed('authService.currentUser', 'authService.isAuthenticated')
  get isAdmin() {
    if (this.authService.currentUser !== undefined) {
      if (this.authService.currentUser.role === UserRoleEnum.Admin) {
        return true;
      }
    }
    return false;
  }

  parseIntInputRounded(input: string | number) {
    input = +input;
    if (isNaN(input)) {
      return 0;
    }
    if (typeof input !== 'string') {
      input = input.toString();
    }
    return Math.round(parseInt(input.replace(',', '.')));
  }
  parseIntInput(input: string | number) {
    input = +input;
    if (isNaN(input)) {
      return 0;
    }
    if (typeof input !== 'string') {
      input = input.toString();
    }
    input = input.replace(',', '.');
    if (input.indexOf('.') > 0) {
      let _temp = input.split('.');
      let dec = _temp[1].substring(0, 2);
      input = _temp[0] + '.' + dec;
    }

    return +input;
  }
  @action
  onFocusOut() {
    this.set('makrodurchmesser', this.parseIntInput(this.makrodurchmesser));
    this.set('makrodurchmesserpreview', 150);
    this.set('makrodurchmesserroundingpreview', 75);
  }
  @computed('recomputeBlocks', 'makrobreite', 'makrohoehe', 'makrorundung')
  get block() {
    this.set('makrobreite', this.parseIntInput(this.makrobreite));
    this.set('makrohoehe', this.parseIntInput(this.makrohoehe));
    this.set('makrorundung', this.parseIntInput(this.makrorundung));

    // Makro Rechteck skalieren #259
    if (this.makrobreite > this.makrohoehe) {
      let makrobreiteskaler = this.makrobreite;
      this.set('makrobreitepreview', 150);

      makrobreiteskaler = this.makrobreitepreview / this.makrobreite;
      this.set('makrohoehepreview', this.makrohoehe * makrobreiteskaler);
      this.set('makrorundungpreview', this.makrorundung * makrobreiteskaler);
    } else {
      let makrohoeheskaler = this.makrohoehe;
      this.set('makrohoehepreview', 150);

      makrohoeheskaler = this.makrohoehepreview / this.makrohoehe;
      this.set('makrobreitepreview', this.makrobreite * makrohoeheskaler);
      this.set('makrorundungpreview', this.makrorundung * makrohoeheskaler);
    }

    this.set('makrobreitepreview', this.parseIntInput(this.makrobreitepreview));
    this.set('makrohoehepreview', this.parseIntInput(this.makrohoehepreview));
    this.set('makrorundungpreview', this.parseIntInput(this.makrorundungpreview));
    return {
      id: 240475,
      fimabId: 240475,
      name: `Rechteck: B ${this.makrobreite} H ${this.makrohoehe} R ${this.makrorundung}`,
      w: this.parseIntInput(this.makrobreite),
      h: this.parseIntInput(this.makrohoehe),
      macro: !this.isComponentType,
      minW: 3,
      maxW: 1000,
      minH: 3,
      maxH: 1000,
      margin: this.COMPONENT_MARGIN,
      offset: {
        x: 0,
        y: 0
      },
      rounding: this.parseIntInput(this.makrorundung)
    };
  }

  @computed('recomputeBlocks', 'makrodurchmesser')
  get block2() {
    return {
      id: 240473,
      fimabId: 240473,
      name: `Rundloch: D ${this.makrodurchmesser}`,
      w: this.parseIntInput(this.makrodurchmesser),
      h: this.parseIntInput(this.makrodurchmesser),
      macro: !this.isComponentType,
      minW: 3,
      maxW: 1000,
      minH: 3,
      maxH: 1000,
      margin: this.COMPONENT_MARGIN,
      offset: {
        x: 0,
        y: 0
      },
      rounding: this.parseIntInput(this.makrodurchmesser) / 2
    };
  }

  @computed('recomputeBlocks', 'makrolanglochbreite', 'makrolanglochdurchmesser')
  get block3() {
    return {
      id: uuid(),
      name: `Langloch`,
      h: this.makrolanglochdurchmesser,
      w: this.makrolanglochbreite,
      macro: !this.isComponentType,
      rounding: this.makrolanglochdurchmesser / 2
    };
  }

  toggleRecompute() {
    this.toggleProperty('recomputeBlocks');
  }

  @computed('rect')
  get validSingleInput() {
    return this.rect !== undefined;
  }

  @computed('rect', 'makroSpaceX', 'makroSpaceY', 'makroAmountX', 'makroAmountY')
  get validMultiInput() {
    return (
      this.rect !== undefined &&
      ((this.makroSpaceX !== undefined && this.makroAmountX > 1) ||
        (this.makroSpaceY !== undefined && this.makroAmountY > 1))
    );
  }

  resetAdvancedForm() {
    // this.set('selectedType', 'Rechteck'); // Dont reset anymore https://projekte.netzreich.de/fimab/fimab/-/issues/584#note_56758
    this.set('selectedComponent', undefined);
    this.set('selectedInstance', undefined);
    this.set('makroPosX', undefined);
    this.set('makroPosY', undefined);
    this.set('makroRotation', 0);
    this.set('makroWidth', undefined);
    this.set('makroHeight', undefined);
    this.set('makroRounding', 0);
    this.set('makroSpaceX', undefined);
    this.set('makroSpaceY', undefined);
    this.set('makroAmountX', 1);
    this.set('makroAmountY', 1);
    this.FIMAB_CONFIGURATOR.advancedAddComponentPreviews([]);
  }

  get FIMAB_CONFIGURATOR(): any {
    return (window as any).FIMAB_CONFIGURATOR;
  }

  @computed(
    'selectedType',
    'makroWidth',
    'makroHeight',
    'makroRounding',
    'makroDiameter',
    'selectedSize',
    'makroDiameterFromSize',
    'makroLength',
    'selectedComponent',
    'selectedInstance'
  )
  get componentName() {
    switch (this.selectedType) {
      case 'Rechteck':
        return `Rechteck: B ${this.makroWidth} H ${this.makroHeight} R ${this.makroRounding}`;

      case 'Rundloch':
        return `Rundloch: D ${this.makroDiameter}`;

      case 'Nietmutter':
        return `Nietmutter: ${this.selectedSize}`;

      case 'Gewinde':
        return `Gewinde: ${this.selectedSize}`;

      case 'Gewindebolzen':
        return `Gewindebolzen: ${this.selectedSize} L ${this.makroLength}`;

      case 'Komponente':
        return this.selectedComponent ? this.selectedComponent?.name : this.selectedInstance.name;
      default:
        console.error(`Unknown component type ${this.selectedType}`);
        break;
    }

    return '';
  }

  @computed(
    'makroPosX',
    'makroPosY',
    'makroHeight',
    'makroWidth',
    'makroRotation',
    'makroDiameter',
    'makroDiameterFromSize'
  )
  get rect() {
    if (this.selectedType === 'Rechteck') {
      if (
        this.makroHeight == undefined ||
        this.makroWidth == undefined ||
        this.makroRotation == undefined ||
        this.makroPosX == undefined ||
        this.makroPosY == undefined
      ) {
        return undefined;
      }
      return {
        w: +this.makroWidth,
        h: +this.makroHeight,
        r: +this.makroRotation,
        x: +this.makroPosX,
        y: +this.makroPosY
      };
    } else {
      if (this.makroPosX == undefined || this.makroPosY == undefined || this.makroDiameter == undefined) {
        return undefined;
      }

      let diameter = +this.makroDiameter;

      if (this.selectedType != 'Rundloch') {
        diameter = this.makroDiameterFromSize;
      }

      return {
        w: diameter,
        h: diameter,
        r: +this.makroRotation,
        rounding: diameter / 2,
        x: +this.makroPosX,
        y: +this.makroPosY
      };
    }
  }

  // Submit
  @action
  addAdvancedComponent() {
    this.evalAll();

    clearTimeout(this.previewCooldown);
    if (this.advancedError !== '') return;
    if (!this.checks()) return;

    const FIMAB_CONFIGURATOR = this.FIMAB_CONFIGURATOR;
    if (FIMAB_CONFIGURATOR == undefined) {
      console.error('FIMAB_CONFIGURATOR is undefined');
      return;
    }

    let res;

    this.set('isSubmitting', true);
    if (this.submittingTimeout) clearTimeout(this.submittingTimeout);
    if (this.selectedInstance) {
      const update = {
        id: this.selectedInstance.id,
        w: +this.rect!.w,
        h: +this.rect!.h,
        macro: this.selectedInstance.macro,
        ...(this.selectedComponent ?? {}),
        r: +this.rect!.r,
        rounding: this.rect?.rounding ?? +this.makroRounding!,
        centerX: +this.rect!.x,
        centerY: +this.rect!.y,
        x: +this.rect!.x - this.rect!.w / 2,
        y: +this.rect!.y - this.rect!.h / 2,
        preciseDiameter: this.makroDiameterFromSize,
        makroLength: this.makroLength,
        name: this.componentName
      };
      console.log(this.componentName);

      this.FIMAB_CONFIGURATOR.updateComponentInstancePositionFromApp(update);

      if (this.makroSpaceX != undefined || this.makroSpaceY != undefined) {
        res = this.placeMultipleAdvancedMakros(true);
        if (!res) return;
        if (!this.selectedInstance) {
          this.resetAdvancedForm();
          document.getElementById('initial-advanced-focus')?.focus();
        }
      }
    } else {
      if (this.makroSpaceX == undefined && this.makroSpaceY == undefined) {
        res = this.placeSingleAdvancedMakro(this.rect, this.makroRounding);
        if (!res) return;
        this.resetAdvancedForm();
        document.getElementById('initial-advanced-focus')?.focus();
      } else {
        res = this.placeMultipleAdvancedMakros(false);
        if (!res) return;
        if (!this.selectedInstance) {
          this.resetAdvancedForm();
          document.getElementById('initial-advanced-focus')?.focus();
        }
      }
    }

    const timeout = setTimeout(() => {
      this.set('isSubmitting', false);
    }, 500);
    this.set('submittingTimeout', timeout);
  }

  getMultipleInput(skipFirst = false, preview = false) {
    this.evalAll();
    if (!this.rect) return;
    const inputs: any[] = [];
    const components = this.FIMAB_CONFIGURATOR.getComponents();

    // Deleted on X axis
    let instancesToRemove: any[] = [];
    if (this.selectedInstance?.copy?.makroAmountX > this.makroAmountX) {
      instancesToRemove.push(
        ...components.filter(
          (comp: any) =>
            this.selectedInstance.children?.some((child: any) => child == comp.id) && comp.indexX >= this.makroAmountX
        )
      );
    }

    // Deleted on Y axis
    if (this.selectedInstance?.copy?.makroAmountY > this.makroAmountY) {
      instancesToRemove.push(
        ...components.filter(
          (comp: any) =>
            this.selectedInstance.children?.some((child: any) => child == comp.id) && comp.indexY >= this.makroAmountY
        )
      );
    }

    if (instancesToRemove.length > 0) {
      if (!preview) {
        this.FIMAB_CONFIGURATOR.deleteComponentInstances(instancesToRemove);
      } else {
        inputs.push(
          ...instancesToRemove.map((inst: any) => {
            let id = this.selectedTypeID;
            if (this.selectedInstance) {
              id = this.selectedInstance.fimabId;
            }

            return {
              data: {
                w: this.selectedComponent?.w || this.parseIntInput(inst.w),
                h: this.selectedComponent?.h || this.parseIntInput(inst.h),
                id,
                componentId: this.isComponentType ? id : undefined,
                fimabId: this.isComponentType ? undefined : id,
                name: 'PREVIEW: ' + this.componentName,
                macro: !this.isComponentType,
                minW: 3,
                maxW: 1000,
                minH: 3,
                maxH: 1000,
                margin: this.COMPONENT_MARGIN,
                toDelete: true,
                rounding: this.rect?.rounding ?? this.makroRounding,
                preciseDiameter: this.makroDiameterFromSize,
                makroLength: this.makroLength,
                isChild: inst.isChild
              },
              rect: {
                w: this.selectedComponent?.w || +inst.w,
                h: this.selectedComponent?.h || +inst.h,
                x: +inst.centerX,
                y: +inst.centerY,
                r: +inst.r
              }
            };
          })
        );
      }
    }

    for (const x of Array.from(Array(this.makroAmountX != undefined ? +this.makroAmountX : 1).keys())) {
      for (const y of Array.from(Array(this.makroAmountY != undefined ? +this.makroAmountY : 1).keys())) {
        if (skipFirst && x == 0 && y == 0) continue;

        const currentX = this.rect.x + x * (this.makroSpaceX != undefined ? +this.makroSpaceX : 0);
        const currentY = this.rect.y + y * (this.makroSpaceY != undefined ? +this.makroSpaceY : 0);

        // If that component already exists we skip it
        if (this.selectedInstance && !preview) {
          const existingComponent = components.find((c: any) => {
            return (
              c.indexX == x && c.indexY == y && this.selectedInstance.children?.some((child: any) => child == c.id)
            );
          });

          if (existingComponent !== undefined) {
            // Update existing components instead of creating new ones
            const update = {
              x: currentX - this.rect.w / 2,
              y: currentY - this.rect.h / 2,
              id: existingComponent.id,
              name: this.componentName,
              centerX: currentX,
              centerY: currentY,
              w: this.selectedComponent?.w || this.rect.w,
              h: this.selectedComponent?.h || this.rect.h,
              r: this.rect.r,
              rounding: this.rect?.rounding ?? +this.makroRounding,
              macro: existingComponent.macro,
              preciseDiameter: this.makroDiameterFromSize,
              makroLength: this.makroLength,
              isChild: existingComponent.isChild
            };

            this.FIMAB_CONFIGURATOR.updateComponentInstancePositionFromApp(update);
            continue;
          }
        }

        const isMain = x == 0 && y == 0;

        let id = this.selectedTypeID;
        if (this.selectedInstance && this.selectedInstance.fimabId) {
          id = this.selectedInstance.fimabId;
        }

        const data: any = {
          w: this.selectedComponent?.w || this.parseIntInput(this.rect.w),
          h: this.selectedComponent?.h || this.parseIntInput(this.rect.h),
          id,
          fimabId: id,
          componentId: this.isComponentType ? id : undefined,
          name: this.componentName,
          macro: this.selectedInstance ? this.selectedInstance.macro : !this.isComponentType,
          minW: 3,
          maxW: 1000,
          minH: 3,
          maxH: 1000,
          margin: this.COMPONENT_MARGIN,
          indexX: x,
          indexY: y,
          main: isMain,
          isChild: !isMain,
          preciseDiameter: this.makroDiameterFromSize,
          makroLength: this.makroLength,
          copy: isMain
            ? {
                makroAmountX: +this.makroAmountX,
                makroAmountY: +this.makroAmountY,
                makroSpaceX: +(this.makroSpaceX ?? 0),
                makroSpaceY: +(this.makroSpaceY ?? 0)
              }
            : undefined,
          offset: {
            x: 0,
            y: 0
          },
          rounding: this.rect.rounding ?? this.parseIntInput(this.makroRounding)
        };

        inputs.push({
          rect: {
            w: this.selectedComponent?.w || +this.rect.w,
            h: this.selectedComponent?.h || +this.rect.h,
            x: +currentX,
            y: +currentY,
            r: +this.rect.r
          },
          data
        });
      }
    }

    return inputs;
  }

  checks() {
    if (!this.rect || this.makroRounding == undefined) {
      this.set('advancedError', 'Alle Felder ausfüllen');
      clearTimeout(this.previewCooldown);
      return false;
    }
    if (this.makroAmountX && this.makroAmountY && this.makroAmountX * this.makroAmountY > 20) {
      this.set('advancedError', 'TOO_MANY_COPIES');
      clearTimeout(this.previewCooldown);
      return false;
    }

    if (this.makroSpaceX && this.makroWidth && +this.makroWidth > +this.makroSpaceX - 5) {
      this.set('advancedError', 'SPACE_X_TOO_SMALL');
      clearTimeout(this.previewCooldown);
      return false;
    }

    if (this.makroSpaceY && this.makroHeight && +this.makroHeight > +this.makroSpaceY - 5) {
      this.set('advancedError', 'SPACE_X_TOO_SMALL');
      clearTimeout(this.previewCooldown);
      return false;
    }
    return true;
  }

  private previewCooldown = undefined;
  @action
  keyUpAdvanced(value: string | number, target: string) {
    clearTimeout(this.previewCooldown);
    if ((event && (event as KeyboardEvent).keyCode == 13) || (event as KeyboardEvent).code == 'Enter') {
      return;
    }
    if (!value) {
      return;
    }
    value = value.toString();
    value = value.replace(/[A-Za-z!@#$%^&()]/g, '').replace(/,/g, '.');
    this.set(target, value);
    this.set('advancedError', '');

    const timeout = setTimeout(() => {
      if (!this.checks()) return;
      if (!this.rect) return;
      if ((this.selectedInstance && this.makroSpaceX != undefined) || this.makroSpaceY != undefined) {
        this.placeMutliplePreviews(true);
        return;
      }

      if (this.makroSpaceX == undefined && this.makroSpaceY == undefined) {
        this.placeSinglePreview();
      } else {
        this.placeMutliplePreviews();
      }
    }, 500);
    this.set('previewCooldown', timeout);
  }

  private placeMutliplePreviews(skipFirst = false) {
    if (!this.rect || this.makroRotation == undefined) {
      return;
    }

    const inputs = this.getMultipleInput(skipFirst, true);
    if (!inputs) return;

    if (!this.placeMultipleSoftCheck(skipFirst)) return;
    this.FIMAB_CONFIGURATOR.advancedAddComponentPreviews(inputs);
  }

  private placeSinglePreview() {
    if (!this.rect || this.makroRounding == undefined) {
      return;
    }

    const checkRes = this.FIMAB_CONFIGURATOR.advancedPositionCheck(this.rect, this.selectedInstance, true);

    if (checkRes !== true) {
      this.set('advancedError', checkRes);
      return;
    }

    const comp = {
      w: this.selectedComponent?.w || this.parseIntInput(this.rect.w),
      h: this.selectedComponent?.h || this.parseIntInput(this.rect.h)
    };

    const data = {
      fimabId: this.selectedTypeID,
      componentId: this.isComponentType ? this.selectedTypeID : undefined,
      name: this.componentName,
      macro: !this.isComponentType,
      minW: 3,
      maxW: 1000,
      minH: 3,
      maxH: 1000,
      margin: this.COMPONENT_MARGIN,
      preciseDiameter: this.makroDiameterFromSize,
      makroLength: this.makroLength,
      offset: {
        x: 0,
        y: 0
      },
      rounding: this.rect.rounding ?? this.parseIntInput(this.makroRounding),
      ...comp
    };

    this.FIMAB_CONFIGURATOR.advancedAddComponentPreviews([{ rect: { ...this.rect, ...comp }, data }]);
  }

  private placeMultipleHardCheck(skipFirst = false) {
    if (!this.rect) return false;

    // const components = this.FIMAB_CONFIGURATOR.getComponents();
    for (const x of Array.from(Array(this.makroAmountX != undefined ? +this.makroAmountX : 1).keys())) {
      for (const y of Array.from(Array(this.makroAmountY != undefined ? +this.makroAmountY : 1).keys())) {
        if (skipFirst && x == 0 && y == 0) continue;
        const currentX = this.rect.x + x * (this.makroSpaceX != undefined ? +this.makroSpaceX : 0);
        const currentY = this.rect.y + y * (this.makroSpaceY != undefined ? +this.makroSpaceY : 0);

        // if (this.selectedInstance) {
        //   const existingComponent = components.find((c: any) => {
        //     return (
        //       c.indexX == x && c.indexY == y && this.selectedInstance.children?.some((child: any) => child == c.id)
        //     );
        //   });
        //   console.log(existingComponent);

        //   if (existingComponent !== undefined) continue;
        // }

        const rectCheck = {
          x: +currentX,
          y: +currentY,
          w: +this.rect.w,
          h: +this.rect.h,
          r: this.rect.r
        };
        const res = this.FIMAB_CONFIGURATOR.advancedPositionCheck(rectCheck, this.selectedInstance, true);

        if (res !== true) {
          console.log(rectCheck, res);
          this.set('advancedError', res);
          return false;
        }
      }
    }

    return true;
  }

  private placeMultipleSoftCheck(skipFirst = false) {
    if (!this.rect) return false;
    const xBase = this.rect.x - this.rect.w / 2;
    const yBase = this.rect.y - this.rect.w / 2;

    let w,
      h = 0;
    if (this.makroAmountX && this.makroSpaceX) {
      w = this.rect.w * this.makroAmountX + (this.makroSpaceX - this.rect.w) * (this.makroAmountX - 1);
    } else {
      w = this.rect.w;
    }
    if (this.makroAmountY && this.makroSpaceY) {
      h = this.rect.h * this.makroAmountY + (this.makroSpaceY - this.rect.h) * (this.makroAmountY - 1);
    } else {
      h = this.rect.h;
    }

    const multipleRect = {
      w,
      h,
      x: w / 2 + xBase,
      y: h / 2 + yBase,
      r: 0
    };

    const checkRes = this.FIMAB_CONFIGURATOR.advancedPositionCheck(multipleRect, this.selectedInstance, true, false);

    if (checkRes !== true) {
      console.log(multipleRect, checkRes);
      const hardCheck = this.placeMultipleHardCheck(skipFirst);

      if (hardCheck !== true) {
        return false;
      }
    }

    // if (this.makroSpaceX !== undefined && this.makroAmountX > 1 && +this.makroSpaceX < this.rect.w) {
    //   this.set('advancedError', 'SPACE_X_TOO_SMALL');
    //   return false;
    // }

    // if (this.makroSpaceY !== undefined && this.makroAmountY > 1 && +this.makroSpaceY < this.rect.h) {
    //   this.set('advancedError', 'SPACE_Y_TOO_SMALL');
    //   return false;
    // }
    return true;
  }

  private placeMultipleAdvancedMakros(skipFirst = false) {
    const inputs = this.getMultipleInput(skipFirst);
    if (!inputs) return;
    if (this.previewCooldown) clearTimeout(this.previewCooldown);

    if (!this.placeMultipleSoftCheck(skipFirst)) {
      return;
    }

    let main = undefined;
    if (skipFirst) {
      main = this.selectedInstance;
      main.w = +main.w;
      main.h = +main.h;
      main.preciseDiameter = this.makroDiameterFromSize;

      main.makroLength = this.makroLength;
      main.copy = {
        makroAmountX: this.makroAmountX,
        makroAmountY: this.makroAmountY,
        makroSpaceX: this.makroSpaceX,
        makroSpaceY: this.makroSpaceY
      };
    }

    this.FIMAB_CONFIGURATOR.advancedAddComponents(inputs, main);
    this.FIMAB_CONFIGURATOR.advancedAddComponentPreviews([]);
    return true;
  }

  private placeSingleAdvancedMakro(rect: any, rounding: number) {
    const checkRes = this.FIMAB_CONFIGURATOR.advancedPositionCheck(rect);

    if (this.previewCooldown) clearTimeout(this.previewCooldown);
    if (checkRes !== true) {
      this.set('advancedError', checkRes);
      return;
    }

    const data = {
      preciseDiameter: this.makroDiameterFromSize,

      makroLength: this.makroLength,
      id: this.selectedTypeID,
      fimabId: this.selectedTypeID,
      componentId: this.isComponentType ? this.selectedTypeID : undefined,
      name: this.componentName,
      w: this.parseIntInput(rect.w),
      h: this.parseIntInput(rect.h),
      macro: !this.isComponentType,
      minW: 3,
      maxW: 1000,
      minH: 3,
      maxH: 1000,
      margin: this.COMPONENT_MARGIN,
      offset: {
        x: 0,
        y: 0
      },
      rounding: this.rect?.rounding ?? this.parseIntInput(rounding),
      ...(this.selectedComponent ?? {})
    };

    this.FIMAB_CONFIGURATOR.advancedAddComponents([
      { rect: { ...this.rect, ...(this.selectedComponent ?? {}) }, data }
    ]);

    return true;
  }

  @action
  showComponents() {
    this.set('componentsView', '1');
  }
  @action
  showMakros() {
    this.set('componentsView', '2');
  }
  @action
  showExtended() {
    this.set('componentsView', '3');
  }
  @action
  showLayouts() {
    this.set('componentsView', '4');
  }
  @action
  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    return false;
  }

  readonly newComponents = [333541, 346128, 346134, 346136, 346137, 346138, 346139];
  @computed('components', 'activeFilter', 'searchString')
  get filteredComponents() {
    return this.get('components').then((components: any) => {
      components = components
        .map((component: any) => {
          if (this.newComponents.includes(component.fimabId)) {
            component['new'] = true;
          }
          return component;
        })
        .sort((a: any, b: any) => {
          if (a.new && !b.new) return -1;
          else if (b.new && !a.new) return 1;
          return 0;
        });
      if (isEmpty(this.activeFilter) && isEmpty(this.searchString)) {
        return components;
      }
      if (!isEmpty(this.activeFilter)) {
        components = components.filterBy('category', this.activeFilter);
      }
      if (!isEmpty(this.searchString)) {
        components = components.filter((component: any) => {
          let componentName = component.name.toLowerCase();
          return componentName.includes(this.searchString.toLowerCase());
        });
      }

      return components;
    });
  }

  COMPONENT_MARGIN = 5;

  @computed()
  get components() {
    return this.componentService.componentApi.componentControllerFindAll(0, 100).then((data) => {
      const items = data.data;
      return items.map((component) => {
        (component as any).open = false;
        return {
          id: component.fimabId,
          fimabId: component.fimabId,
          name: component.name,
          category: component.category,
          w:
            component.outerWidth > 0
              ? component.outerWidth + this.COMPONENT_MARGIN * 2
              : component.innerWidth + this.COMPONENT_MARGIN * 2,
          h:
            component.outerHeight > 0
              ? component.outerHeight + this.COMPONENT_MARGIN * 2
              : component.innerHeight + this.COMPONENT_MARGIN * 2,
          margin: this.COMPONENT_MARGIN,
          offset: {
            x: 0,
            y: 0
          }
        };
      });
    });
  }

  @computed()
  get options() {
    let options: any[] = [];
    return this.get('components').then((components) => {
      components.forEach((element: any) => {
        if (!options.includes(element.category)) {
          options.pushObject(element.category);
        }
      });
      options.unshiftObject('Alle');
      return options;
    });
  }

  @action
  searchByString() {}

  dropTargetSelector = '.fimab-board__cabinetSurface';
  dragObject: any | undefined;
  cabinetSurfaceRect: any;
  dragPreviewElement: any;

  @action handleComponentItemMouseDown(component: any, event: MouseEvent) {
    if ((event.target as any)?.id === 'component-add') return;

    set(this, 'dragPreviewElement', this.createDragPreviewElement(component, event.pageX, event.pageY));
    this.FIMAB_CONFIGURATOR.dragObject = component;
    document.addEventListener('mousemove', this.handleComponentItemMouseMove, true);
    document.addEventListener('mouseup', this.handleComponentItemMouseUp, false);
  }

  handleComponentItemMouseUp = (/* event: MouseEvent */) => {
    if (!this.FIMAB_CONFIGURATOR.dragObject) {
      return;
    }
    this.FIMAB_CONFIGURATOR.dragObject = undefined;
    this.dragPreviewElement!.remove();
    document.removeEventListener('mousemove', this.handleComponentItemMouseMove, true);
    document.removeEventListener('mouseup', this.handleComponentItemMouseUp, false);
    this.saveOrder();
    (window as any).isDragging = false;
  };

  handleComponentItemMouseMove = (event: MouseEvent) => {
    if (!this.FIMAB_CONFIGURATOR.dragObject) {
      return;
    }
    const x = event.pageX;
    const y = event.pageY;
    this.dragPreviewElement!.css({ top: y, left: x });

    (window as any).isDragging = true;
    if ((event.target as any).classList.contains('fimab-board__componentInstanceContentInner')) {
      event.stopPropagation();
    }
  };

  @action handleChangeCabinetMeta(data: any) {
    set(this, 'cabinetSurfaceRect', data);
  }

  createDragPreviewElement(component: any, x: number, y: number) {
    const w = component.w * this.FIMAB_CONFIGURATOR.cabinetSurfaceRect!.ratio;
    const h = component.h * this.FIMAB_CONFIGURATOR.cabinetSurfaceRect!.ratio;
    const scale = (this.FIMAB_CONFIGURATOR.zoomfactor ?? 0) / 2 + 1;
    return Ember.$(`<div><img src="/components/${component.fimabId}_FRO.PNG"></div`)
      .addClass('componentDragPreview')
      .css({
        width: w * scale,
        height: h * scale,
        top: y,
        left: x
      })
      .appendTo('body');
  }

  @action
  openModal(singleComponent: any) {
    const origin = window.location.origin;
    window.open(
      `${origin}/components/${singleComponent.fimabId}.PDF`,
      `${singleComponent.name}`,
      'height=800,width=1200,menubar=no,status=no,titlebar=no,toolbar=no'
    );
  }

  @action
  closeModal(singleComponent: any) {
    set(singleComponent, 'open', false);
  }
}
