import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';
import { Address } from '../../services/api-client';

@tagName('test')
export default class CompanyAddressSelect extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  open: boolean = false;

  readonly = false;

  options: Address[] = [];

  // Selected option
  activeAddress?: Address;
  selectedIndex = 0;

  hoveredIndex = 0;

  // eslint-disable-next-line no-unused-vars
  onChange?: (address: Address) => void;

  async didReceiveAttrs() {
    this.set(
      'selectedIndex',
      (await this.options).findIndex((o) => this.activeAddress?.id == o.id)
    );
  }

  @action
  keyDown(event: KeyboardEvent) {
    if (!this.open) {
      return;
    }
    event.preventDefault();
    if (event.keyCode === 40 || (event.keyCode === 9 && !event.shiftKey)) {
      this.set('hoveredIndex', (this.hoveredIndex + 1) % this.options.length);
    }
    if (event.keyCode === 38 || (event.keyCode === 9 && event.shiftKey)) {
      if (this.hoveredIndex == 0) {
        this.set('hoveredIndex', this.options.length - 1);
      } else {
        this.set('hoveredIndex', (this.hoveredIndex - 1) % this.options.length);
      }
    }
    if (event.keyCode === 13 && this.open) {
      this.set('activeAddress', this.options[this.hoveredIndex]);
      this.set('selectedIndex', this.hoveredIndex);
      this.set('open', false);
    }
  }

  @action
  clickSelect() {
    if (this.readonly) return;
    this.toggleProperty('open');
  }

  @action
  setFilter(option: Address, selectedIndex: number) {
    if (!this.open) return;
    this.set('selectedIndex', selectedIndex);
    this.set('activeAddress', option);

    this.change();

    this.set('open', false);
  }

  @action
  change() {
    if (this.onChange && this.activeAddress) {
      this.onChange(this.activeAddress);
    }
  }
}
