import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { run } from '@ember/runloop';
import OrderService from '../../services/order';
import AuthService from '../../services/auth';
import { Address, Order, UserRoleEnum } from '../../services/api-client';
import { deliveryDate } from '../../helpers/delivery-date';
import OrganizationService from '../../services/organization';

export default class AppCalculation extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify: any;
  @service('order') orderService!: OrderService;
  @service('auth') authService!: AuthService;
  @service('organization') orgService!: OrganizationService;
  @service() notify: any;
  @service() router: any;
  @service() toast: any;
  @service() intl: any;

  /**
   * Alternate Address
   */
  changeShippingAddress = false;
  changePaymentAddress = false;

  count: number = 1;
  createOrderConfirmationDone = false;
  redirect: string = '';

  // Angebotsmodal
  showOfferModal: boolean = false;
  showOfferModalConfirmation: boolean = false;

  successMessageAngebot: boolean = false;
  isCreatingConfirmation: boolean = false;
  isLoading: boolean = false;

  errors: object = {};

  successMessage: boolean = false;
  agb: boolean = false;
  widerruf: boolean = false;

  selectedDeliveryDate: Date = new Date();
  blockDays: string[] = [];
  minDate? = new Date();
  maxDate? = new Date();

  deliverydate?: string;
  hour?: number;
  weekday?: number;
  daystoadd?: number;
  morgen?: string;
  dayplus: number = 0;

  confirmationBody: string = '';
  confirmationBodyEnd: string = '';

  isReOrder = false;

  thumbs: object[] = [
    {
      src: window.location.origin + '/schrank2.jpg',
      w: 800,
      h: 600,
      title: 'Thumb1'
    },
    {
      src: window.location.origin + '/schrank2.jpg',
      w: 800,
      h: 600,
      title: 'Thumb1'
    },
    {
      src: window.location.origin + '/schrank2.jpg',
      w: 800,
      h: 600,
      title: 'Thumb1'
    },
    {
      src: window.location.origin + '/schrank2.jpg',
      w: 800,
      h: 600,
      title: 'Thumb1'
    }
  ];

  constructor() {
    super(...arguments);
    let d = Date.now();
    this.set('timestamp', d);
    setTimeout(() => {
      this.amplify.trigger('3DView:screenshot');
    }, 400);
  }

  @computed('orgService.currentOrganization')
  get defaultAddress() {
    return this.orgService.currentOrganization?.addresses.find((a) => a.isDefault);
  }

  @action
  updateAlternatShipping(address: Address) {
    this.set('model.moduldefinition.alternateShipping.company', this.orgService.currentOrganization?.name);
    this.set(
      'model.moduldefinition.alternateShipping.name',
      this.authService.currentUser?.firstName + ' ' + this.authService.currentUser?.lastName
    );
    this.set('model.moduldefinition.alternateShipping.address', address.street);
    this.set('model.moduldefinition.alternateShipping.zip', address.zip);
    this.set('model.moduldefinition.alternateShipping.city', address.city);
  }

  parseDate(input: string) {
    var parts = input.match(/(\d+)/g);
    if (parts == null) return new Date();
    // note parts[1]-1
    return new Date(+parts[2], +parts[1] - 1, +parts[0]);
  }

  async initHolidays() {
    this.set('minDate', new Date(this.parseDate(await this.orderService.deliveryDate.date)));

    const maxDate = new Date(this.minDate!.getTime());
    maxDate.setDate(maxDate.getDate() + 60);
    this.set('maxDate', maxDate);

    const weekends = [];
    for (let date = new Date(this.minDate!.getTime()); date <= this.maxDate!; date.setDate(date.getDate() + 1)) {
      if (date.getDay() == 0 || date.getDay() == 6) {
        weekends.push(new Date(date));
      }
    }

    this.set('blockDays', [
      ...(await this.orderService.holidays).map((holiday: any) => new Date(holiday)),
      ...weekends
    ]);
  }

  @computed('authService.currentUser', 'authService.isAuthenticated')
  get isAdmin() {
    if (this.authService.currentUser !== undefined) {
      if (this.authService.currentUser.role === UserRoleEnum.Admin) {
        return true;
      }
    }
    return false;
  }

  async didReceiveAttrs() {
    await this.initHolidays();

    this.set('deliverydate', await this.orderService.deliveryDate.date);
    this.set('model.deliverydate', await this.orderService.deliveryDate.date);

    if (
      this.model.requestDeliveryDate == undefined ||
      this.model.requestDeliveryDate == '' ||
      this.minDate! > new Date(this.parseDate(this.model.requestDeliveryDate))
    ) {
      this.set('selectedDeliveryDate', this.minDate);
    } else {
      this.set('selectedDeliveryDate', new Date(this.parseDate(this.model.requestDeliveryDate)));
    }

    if (this.finished) {
      this.set('successMessage', true);
      this.set('createOrderConfirmationDone', true);
    }

    // Lieferdatum errechnen ----------------
  }

  @computed('isReOrder')
  get reOrderSettings() {
    return {
      amount: this.model.amount,
      internalOrdernumber: this.model.internalOrdernumber,
      orderNote: this.model.orderNote
    };
  }

  @computed(
    'model',
    'model.moduldefinition',
    'model.moduldefinition.sides',
    'model.moduldefinition.sides.VORNE',
    'model.moduldefinition.sides.HINTEN',
    'model.moduldefinition.sides.LINKS',
    'model.moduldefinition.sides.RECHTS',
    'model.moduldefinition.sides.OBEN',
    'model.moduldefinition.sides.UNTEN',
    'model.moduldefinition.sides.MONTAGEPLATTE'
  )
  get hasAssemblyPart() {
    let sum = 0;
    const sides: any = (this.model as Order).moduldefinition.sides;
    for (const key of Object.keys(sides)) {
      const side = sides[key];
      if (side.components != undefined) {
        sum += side.components.length;
      }
    }

    return sum > 0;
  }

  @action
  saveShippingAddress() {
    this.save();
    this.set('changeShippingAddress', false);
  }

  @action
  openShippingAddressModal() {
    if (this.model.moduldefinition.alternateShipping == undefined) {
      this.set('model.moduldefinition.alternateShipping', {});
    }
    this.set('changeShippingAddress', true);
  }

  @action
  openPaymentAddressModal() {
    if (this.model.moduldefinition.alternatePayment == undefined) {
      this.set('model.moduldefinition.alternatePayment', {});
    }
    this.set('changePaymentAddress', true);
  }

  @action
  savePaymentAddress() {
    this.save();
    this.set('changePaymentAddress', false);
  }

  @action
  onSelectDeliveryDate(dateObj: any) {
    this.set('selectedDeliveryDate', dateObj.date);
    this.set('model.requestDeliveryDate', deliveryDate([dateObj.date]));
    this.save();
  }

  validate() {
    if (!this.agb) {
      this.set('errors.agb', this.intl.t('Steps.Finish.Errors.AGB'));
    }
    if (!this.widerruf) {
      this.set('errors.widerruf', this.intl.t('Steps.Finish.Errors.Revocation'));
    }
    if (isEmpty(this.get('model.name'))) {
      this.set('errors.name', this.intl.t('Steps.Finish.Errors.NameError'));
    }
    if (isEmpty(this.get('model.amount')) || this.get('model.amount') < 1) {
      this.set('errors.amount', this.intl.t('Steps.Finish.Errors.AmountError'));
    }
    if (Object.keys(this.get('errors' as keyof this)).length == 0) {
      return true;
    } else {
      return false;
    }
  }

  @action
  async reOrder() {
    this.set('isLoading3', true);
    this.set('errors', {});

    const reOrderModel = this.get('model');

    if (reOrderModel.user == undefined) {
      reOrderModel.user = this.authService.currentUser;
    }

    reOrderModel.internalOrdernumber = this.reOrderSettings.internalOrdernumber;
    reOrderModel.amount = +this.reOrderSettings.amount;
    reOrderModel.orderNote = this.reOrderSettings.orderNote;

    if (this.validate()) {
      let newOrder = await this.orderService.createNewOrderFromExisting(reOrderModel, true);
      this.set('isLoading3', false);
      if (!newOrder) return;

      this.get('router').transitionTo('konfigurator', newOrder.share, {
        queryParams: { step: 4, finished: true }
      });

      this.set('isReOrder', false);
      this.set('successMessage', true);
      this.set('createOrderConfirmationDone', true);
      this.orderService.set('order', newOrder);
    } else {
      run.later(() => {
        this.set('isLoading3', false);
      }, 500);
    }
  }

  @action
  async createOrder() {
    this.set('isOrdering', true);
    this.set('errors', {});

    if (this.model.user == undefined) {
      this.model.user = this.authService.currentUser;
    }

    if (this.validate()) {
      this.set('isOrdering', false);

      this.set('model', await this.orderService.createOrder(this.model));
      this.set('successMessage', true);
      this.set('createOrderConfirmationDone', true);
    } else {
      run.later(() => {
        this.set('isOrdering', false);
      }, 500);
    }
  }

  @computed('model', 'model.status', 'model.ordernumber')
  get isOrdered() {
    return this.model.status == 'ORDERED' && this.model.ordernumber != undefined && this.model.ordernumber != null;
  }

  confirmationEmail: string | null = null;
  confirmationTitle = '';

  @action save() {
    if (isNaN(this.model.amount) || this.model.amount == 0) this.model.amount = 1;
    this.orderService.saveOrder(this.model);
  }

  @action
  async createEmailTemplate() {
    if (this.model.ordernumber == null) {
      await this.angebot(false);
    }
    this.set(
      'confirmationBodyEndFormatted',
      `Zum Projekt ${this.get('model.name')} wurde folgende Bestellanforderung an Sie gestellt:
      Die Bestellung können Sie unter folgendem Link ausführen.

      <a target="_blank" href="${window.location.href.replace(
        'konfigurator',
        'orderconfirmation'
      )}"><b style="text-decoration:underline;">Bestellungsübersicht</b></a>
    
        Das Angebot im Detail als PDF finden Sie hier.
    
        <a target="_blank" href="#"><b style="text-decoration:underline;">Angebot Download</b></a>`.replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        '$1' + '<br>' + '$2'
      )
    );
    //     let pdf = `https://fimab-filestorage.s3.eu-central-1.amazonaws.com/public/offers/angebot_${this.model.share}.pdf`;

    //     if (this.model.user === null) {
    //       await this.orderService.saveOrder(this.model);

    //       this.set('model', this.orderService.get('order'));
    //     }

    //     this.set(
    //       'confirmationBody',
    //       `Guten Tag,
    // anbei erhalten Sie die Bestellanforderung von ${this.model.user.firstName} ${this.model.user.lastName}, mit der Bitte die Bestellung auszuführen.`
    //     );
    //     this.set(
    //       'confirmationBodyEnd',
    //       `
    //     Folgende Bestellanforderung wurde erstellt:
    //     Die Bestellung können Sie unter folgendem Link ausführen.

    //     <a target="_blank" href="${window.location.href.replace(
    //       'konfigurator',
    //       'orderconfirmation'
    //     )}"><b style="text-decoration:underline;">Online-Bestellformular</b></a>

    //     Das Angebot im Detail als PDF finden Sie hier.

    //     <a  target="_blank" href="${pdf}"><b style="text-decoration:underline;">Angebot Download</b></a>

    //     --
    //     Die E-Mail und das Angebot wurde mit dem Online Schaltschrank Konfigurator ARMARiO erstellt.
    //     Es freut uns sehr, dass wir auch Sie für unsere kundenspezifisch individuellen Schaltschranklösungen begeistern können.
    //     Auf unserer Internetseite www.armario.eu können sie selbst 24/7 mit wenigen Klicks weitere Modelle auswählen, Lochbilder gestalten, Farben auswählen, Preise einsehen und Bestellungen aufgeben. Egal ob Montag morgens, oder Samstag abends.
    //     Ein Erklärvideo zum weltweit einzigen Konfigurator für Schaltschränke sehen Sie hier:
    //     https://youtu.be/GQyqxLqHtIo
    //     Hier finden Sie 2 Videos zu ganz außergewöhnlichen Schaltschrankkonstruktionen:
    //     https://youtu.be/qNUgZijjQlE - https://youtu.be/vjcyvMdtvCo.
    //     Für Rückfragen stehen wir Ihnen jederzeit gerne zur Verfügung.
    //     FiMAB GmbH & Co. KG
    //     Bühlstrasse 8
    //     75387 Neubulach
    //     Telefon +49 (7053) 96839-0
    //     Telefax +49 (7053) 96839-19
    //     E-Mail: info@fimab.eu
    //     http://www.fimab.eu
    //     Geschäftsführer: Markus Fiedler
    //     Registergericht Amtsgericht Stuttgart, HRB 330801
    //       `
    //     );
    //     this.set(
    //       'confirmationBodyEndFormatted',
    //       `Zum Projekt ${this.get('model.name')} wurde folgende Bestellanforderung an Sie gestellt:
    // Die Bestellung können Sie unter folgendem Link ausführen.

    // <a target="_blank" href="${window.location.href.replace(
    //         'konfigurator',
    //         'orderconfirmation'
    //       )}"><b style="text-decoration:underline;">Bestellungsübersicht</b></a>

    // Das Angebot im Detail als PDF finden Sie hier.

    // <a target="_blank" href="${pdf}"><b style="text-decoration:underline;">Angebot Download</b></a>`.replace(
    //         /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    //         '$1' + '<br>' + '$2'
    //       )
    //     );
  }

  sendingOrderToBuyer = false;
  @action
  async createOrderConfirmation() {
    if (this.confirmationEmail == null) return;
    try {
      this.orderService.createOrderConfirmation({
        targetMail: this.confirmationEmail,
        pdfLink: `https://fimab-filestorage.s3.eu-central-1.amazonaws.com/public/offers/angebot_${this.model.share}.pdf`,
        userText: this.get('confirmationBody').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2'),
        orderLink: window.location.href.replace('konfigurator', 'orderconfirmation')
      });

      // this.set('model.moduldefinition.orderBuyer', this.confirmationEmail);
      this.set('isCreatingConfirmation', false);
      this.set('sendingOrderToBuyer', false);
      this.set('successMessage', true);
      this.set('createOrderConfirmationDone', true);
    } catch (e) {
      console.error(e);
    }
  }

  screenmodal: boolean = false;

  @action
  openScreenModal(screen: object) {
    this.set('activeScreen', screen);
    this.set('screenmodal', true);
  }

  @computed('model.moduldefinition.sides', 'model.moduldefinition')
  get modulComponents() {
    let components: any[] = [];
    for (const key of Object.keys(this.model.moduldefinition.sides)) {
      const side = this.model.moduldefinition.sides[key];
      if (side.components != undefined) {
        for (const component of side.components) {
          if (component.macro) continue;
          if (components.some((c) => component.name == c.name)) {
            components.find((c) => component.name == c.name).count++;
          } else {
            components.push({ ...component, count: 1 });
          }
        }
      }
    }
    return components;
  }

  @action
  async createComponentOffer() {
    try {
      await this.orderService.ordersApi.orderControllerCreateComponentOffer(this.model.id);
      this.set('showOfferModal', false);
      this.set('successMessage', false);
      this.set('showOfferModalConfirmation', true);
    } catch (e) {
      console.log(e);
    }
  }

  @action
  async angebot(download: boolean = true) {
    try {
      this.model.amount = +this.model.amount;
      await this.orderService.saveOrder(this.model, false);
      const res = await this.orderService.ordersApi.orderControllerCreateOffer(this.model.id);
      this.orderService.set('order', res.data);
      this.set('model', res.data);
      if (download) {
        this.downloadPDF();
      }

      if (this.modulComponents.length == 0) return;
      this.set('showOfferModal', true);
    } catch (e) {
      console.log(e);
    }
    return;
    //   const date = new Date();
    //   this.set("isLoading", true);
    //   let ordernumber;

    //   if (isEmpty(this.get("model.ordernumber"))) {
    //     ordernumber = await this.orderService.getNextOrderNumber();

    //     this.set("model.ordernumber", ordernumber);
    //   } else {
    //     ordernumber = this.get("model.ordernumber");
    //   }
    //   if (isEmpty(this.get("model.user"))) {
    //     this.set("model.user", this.get("amplify.currentUser"));
    //   }

    //   await this.orderService.saveOrder(this.get("model"));
    //   await this.orderService.setOrderStatus(this.get("model"), "NEU");
    //   this.set("model.status", "NEU");
    //   const company = this.get("model.user.company");
    //   const fullname = this.get("model.user.fullname");
    //   const street = this.get("model.user.address");
    //   const city = this.get("model.user.city");
    //   const zip = this.get("model.user.zip");
    //   const orderName = this.get("model.name");
    //   const articleNumber = ordernumber;
    //   const internalOrdernumber = this.get("model.internalOrdernumber");
    //   const angebotsnummer = ordernumber;
    //   const count = parseInt(this.get("model.amount"));
    //   const model = this.get("model.cabinet.name");
    //   const breite = this.get("model.moduldefinition.width");
    //   const hoehe = this.get("model.moduldefinition.height");
    //   const laenge = this.get("model.moduldefinition.length");
    //   const ral = this.get("model.color.code");
    //   const material = this.get("model.material");
    //   const finish = this.get("model.finish");
    //   let Baugruppenteile: any[] = [];

    //   if (!isEmpty(this.get("model.moduldefinition.sides.VORNE.components"))) {
    //     Baugruppenteile.pushObjects(
    //       this.get("model.moduldefinition.sides.VORNE.components").map(
    //         (Bauteil: any) => {
    //           Bauteil.type = "VORNE";
    //           return Bauteil;
    //         }
    //       )
    //     );
    //   }
    //   if (!isEmpty(this.get("model.moduldefinition.sides.HINTEN.components"))) {
    //     Baugruppenteile.pushObjects(
    //       this.get("model.moduldefinition.sides.HINTEN.components").map(
    //         (Bauteil: any) => {
    //           Bauteil.type = "HINTEN";
    //           return Bauteil;
    //         }
    //       )
    //     );
    //   }
    //   if (!isEmpty(this.get("model.moduldefinition.sides.LINKS.components"))) {
    //     Baugruppenteile.pushObjects(
    //       this.get("model.moduldefinition.sides.LINKS.components").map(
    //         (Bauteil:any) => {
    //           Bauteil.type = "LINKS";
    //           return Bauteil;
    //         }
    //       )
    //     );
    //   }
    //   if (!isEmpty(this.get("model.moduldefinition.sides.RECHTS.components"))) {
    //     Baugruppenteile.pushObjects(
    //       this.get("model.moduldefinition.sides.RECHTS.components").map(
    //         (Bauteil:any) => {
    //           Bauteil.type = "RECHTS";
    //           return Bauteil;
    //         }
    //       )
    //     );
    //   }
    //   if (!isEmpty(this.get("model.moduldefinition.sides.OBEN.components"))) {
    //     Baugruppenteile.pushObjects(
    //       this.get("model.moduldefinition.sides.OBEN.components").map(
    //         (Bauteil:any) => {
    //           Bauteil.type = "OBEN";
    //           return Bauteil;
    //         }
    //       )
    //     );
    //   }
    //   if (!isEmpty(this.get("model.moduldefinition.sides.UNTEN.components"))) {
    //     Baugruppenteile.pushObjects(
    //       this.get("model.moduldefinition.sides.UNTEN.components").map(
    //         (Bauteil:any) => {
    //           Bauteil.type = "UNTEN";
    //           return Bauteil;
    //         }
    //       )
    //     );
    //   }
    //   if (
    //     !isEmpty(
    //       this.get("model.moduldefinition.sides.MONTAGEPLATTE.components")
    //     )
    //   ) {
    //     Baugruppenteile.pushObjects(
    //       this.get("model.moduldefinition.sides.MONTAGEPLATTE.components").map(
    //         (Bauteil:any) => {
    //           Bauteil.type = "MONTAGEPLATTE";
    //           return Bauteil;
    //         }
    //       )
    //     );
    //   }

    //   let logmodel = this.get("model");
    //   delete logmodel.screens;
    //   try {
    //     this.get("amplify.Storage").put(
    //       `${this.get("model.user.email")}/A_${this.get(
    //         "model.ordernumber"
    //       )}_${new Date().getTime()}.json`,
    //       JSON.stringify(logmodel),
    //       { level: "public" }
    //     );
    //   } catch (e) {
    //     console.error(e);
    //   }

    //   try {
    //     const price = Math.round(this.get("amplify.preis") * 100) / 100;

    //     const email = this.model.user.email;
    //     const emailNotification = await fetch(
    //       "https://h35roba4mf.execute-api.eu-west-1.amazonaws.com/prod/order",
    //       {
    //         method: "POST",
    //         headers: {
    //           Accept: "application/json",
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //           email: "intern",
    //           subject: `Angebot: ${ordernumber} / Kundenbezeichnung: ${orderName}`,
    //           content: `Neues Angebot von ${email}<br>
    //         ${this.model.cabinet.name} - B ${breite}  H ${hoehe}  T ${laenge}
    //         <br>
    //         Summe: ${Math.round(price * 100) / 100}<br>
    //         <br>
    //         `,
    //         }),
    //       }
    //     );
    //     await emailNotification.text();
    //   } catch (e) {
    //     console.log(e);
    //   }

    //   let modelnumber = "";
    //   let tempHoehe = parseInt(this.model.moduldefinition.height);
    //   if (
    //     (this.model.moduldefinition.cabin == 1 ||
    //       this.model.moduldefinition.cabin == 2) &&
    //     tempHoehe > 670
    //   ) {
    //     modelnumber = "239035";
    //   }
    //   if (
    //     (this.model.moduldefinition.cabin == 1 ||
    //       this.model.moduldefinition.cabin == 2) &&
    //     tempHoehe <= 670
    //   ) {
    //     modelnumber = "239035";
    //   }
    //   if (this.model.moduldefinition.cabin == 2) {
    //     modelnumber = "252826";
    //   }
    //   if (this.model.moduldefinition.cabin == 3 && tempHoehe > 1100) {
    //     modelnumber = "252725";
    //   }
    //   if (
    //     this.model.moduldefinition.cabin == 3 &&
    //     tempHoehe > 670 &&
    //     tempHoehe <= 1100
    //   ) {
    //     modelnumber = "240954";
    //   }
    //   if (this.model.moduldefinition.cabin == 3 && tempHoehe <= 670) {
    //     modelnumber = "252825";
    //   }
    //   if (this.model.moduldefinition.cabin == 4) {
    //     modelnumber = "240652";
    //   }
    //   const rawResponse = await fetch("https://fimab-pdf.staging-netzreich.de/", {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/pdf",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify([
    //       {
    //         offerNo: `${angebotsnummer}`,
    //         date:
    //           date.getDate() +
    //           "." +
    //           (date.getMonth() + 1) +
    //           "." +
    //           date.getFullYear(),
    //         internalOrdernumber: internalOrdernumber,
    //         payment: isEmpty(this.get("model.user.payment"))
    //           ? "Zahlbar innerhalb 30 Tagen ohne Abzug"
    //           : this.get("model.user.payment"),
    //         shipping: isEmpty(this.get("model.user.shipping"))
    //           ? "-"
    //           : this.get("model.user.shipping"),
    //         customerNo: this.get("model.user.customerId"),
    //         supplierNo: "",
    //         customerAddress: `${company}<br>${fullname}<br>${street}<br>${zip} ${city}`,
    //         customerAddressee: `${fullname}`,
    //         order: [
    //           {
    //             orderName: `${orderName}`,
    //             orderNote: `${this.model.moduldefinition.orderNote}`,
    //             itemNo: `${articleNumber}_${modelnumber}`,
    //             model: model,
    //             sizeConfig: `B:${breite}, H:${hoehe}, T:${laenge}, ${material}, Ral ${ral} - ${finish}`,
    //             description: Baugruppenteile.map((Bauteil) => {
    //               const name = isEmpty(Bauteil.name)
    //                 ? `Makro B: ${Bauteil.b}mm, L: ${Bauteil.h}mm, D: ${Bauteil.rounding}mm `
    //                 : Bauteil.name;
    //               return `Aussparung ${Bauteil.type} für '${name}' an x:${Bauteil.centerX}, y:${Bauteil.centerY}`;
    //             }),
    //             itemCount: count,
    //             priceEu: Math.round(this.get("amplify.preis") * 100) / 100,
    //           },
    //         ],
    //         customerUSt: this.get("model.user.taxId"),
    //       },
    //     ]),
    //   });
    //   const content = await rawResponse.blob();
    //   let exportFilename = "angebot.pdf";
    //   await this.get("amplify.Storage")
    //     .put(
    //       `angebote/${this.model.share}/angebot_${angebotsnummer}.pdf`,
    //       content,
    //       {
    //         level: "public",
    //         contentType: "application/pdf",
    //       }
    //     )
    //     .then((result:any) => {
    //       return this.get("amplify.Storage").get(result.key, {
    //         level: "public",
    //       });
    //     })
    //     .catch((err:any) => console.log(err));

    //   (window as any).fbq("track", "InitiateCheckout");

    //   if (!download) {
    //     this.set("isLoading", false);

    //     return;
    //   }

    //   //IE11 & Edge
    //   if ((navigator as any).msSaveBlob) {
    //     (navigator as any).msSaveBlob(content, exportFilename);
    //   } else {
    //     //In FF link must be added to DOM to be clicked
    //     var link = document.createElement("a");
    //     link.href = window.URL.createObjectURL(content);
    //     link.setAttribute("download", exportFilename);
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   }
    //   this.set("isLoading", false);
  }

  @action
  async copyOrder() {
    this.set('isLoading2', true);
    let newOrder = await this.orderService.createNewOrderFromExisting(this.get('model'));
    this.set('isLoading2', false);
    console.log(newOrder);
    if (!newOrder) return;

    return this.get('router').transitionTo('konfigurator', newOrder.share);
  }

  @action
  login() {
    if (!this.authService.isAuthenticated) {
      this.authService.toggleProperty('modal');
    }
  }

  @action
  async downloadPDF() {
    let rawResponse = await fetch(
      `https://fimab-filestorage.s3.eu-central-1.amazonaws.com/public/offers/angebot_${this.model.share}.pdf`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf'
        }
      }
    );

    if (rawResponse.status != 200) {
      rawResponse = await fetch(
        `https://fimab-filestorage.s3.eu-central-1.amazonaws.com/public/offers/angebot_${this.model.ordernumber}.pdf`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/pdf'
          }
        }
      );
    }

    if (rawResponse.status != 200) {
      this.toast.error('Da ist etwas schief gelaufen.');
      return;
    }
    const content = await rawResponse.blob();
    let exportFilename = `angebot_${this.model.ordernumber}.pdf`;
    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(content, exportFilename);
    } else {
      //In FF link must be added to DOM to be clicked
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(content);
      link.setAttribute('download', exportFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    this.set('isLoading', false);
  }
}
