import Component from '@ember/component';
import { Address, CreateOrganizationPayload, Organization } from '../../services/api-client';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';
import { computed, action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { validatePresence } from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';
import Changeset from 'ember-changeset';
import OrganizationService from '../../services/organization';
import { observes } from '@ember-decorators/object';

@classNames('contents')
export default class CompanyCard extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;
  @service('organization') organizationService!: OrganizationService;
  @service toast: any;

  org?: Organization;

  addressValidations = {
    street: [validatePresence(true)],
    city: [validatePresence(true)],
    zip: [validatePresence(true)],
    country: [validatePresence(true)]
  };

  orgValidations = {
    name: [validatePresence(true)],
    taxId: [validatePresence(true)]
  };

  orgChangeset: any;
  addressChangeset: any;

  editAddressModalOpen = false;
  addressToEdit?: Address;

  createAddress: Address = {
    street: '',
    city: '',
    zip: '',
    country: '',
    firstName: '',
    lastName: '',
    phone: ''
  } as Address;

  createOrg: Organization = {
    name: '',
    taxId: ''
  } as Organization;

  selectedAddress?: Address;

  onLeave?: () => void;

  // eslint-disable-next-line no-unused-vars
  onCreate?: (payload: CreateOrganizationPayload) => void;

  constructor() {
    super(...arguments);
  }

  didReceiveAttrs(): void {
    const address = this.org?.addresses?.find((a) => a.isDefault);
    if (!this.selectedAddress && address) {
      this.set('selectedAddress', address);
    }
  }

  @observes('selectedAddress')
  selectedAddressChanged() {
    if (!this.selectedAddress) return;
    if (this.selectedAddress.id) {
      this.set('addressToEdit', this.selectedAddress);
    } else {
      this.set('addressToEdit', {});
    }
  }

  @computed('selectedAddress')
  get addressMode() {
    return this.selectedAddress?.id ? 'edit' : 'create';
  }

  @computed('org')
  get addressOptions() {
    if (!this.org) return [];
    return [...this.org.addresses, { street: 'Neue Adresse hinzufügen' }];
  }

  @computed('org')
  get defaultAddress() {
    return this.org?.addresses.find((a) => a.isDefault);
  }

  @action
  saveAddress() {
    if (!this.addressToEdit) return;
    if (this.addressMode === 'edit') {
      this.organizationService
        .updateAddress(this.addressToEdit)
        .then(() => {
          this.toast.success('Adresse erfolgreich gespeichert');
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.organizationService
        .createAddress(this.addressToEdit)
        .then(() => {
          this.organizationService.loadOrganization();
          this.toast.success('Adresse erfolgreich erstellt');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  @action
  editAddress() {
    this.set('editAddressModalOpen', true);
    this.set(
      'addressToEdit',
      this.org?.addresses.find((a) => a.isDefault)
    );
  }

  @action
  leaveCompany() {
    if (this.onLeave) {
      this.onLeave();
    }
  }

  @action
  async submit() {
    this.set(
      'addressChangeset',
      new Changeset(this.createAddress, lookupValidator(this.addressValidations), this.addressValidations)
    );
    await this.addressChangeset.validate();

    this.set('orgChangeset', new Changeset(this.createOrg, lookupValidator(this.orgValidations), this.orgValidations));
    await this.orgChangeset.validate();

    if (!this.addressChangeset.get('isValid') || !this.orgChangeset.get('isValid')) {
      return;
    }

    const dto: CreateOrganizationPayload = {
      name: this.createOrg.name,
      taxId: this.createOrg.taxId,
      addresses: [this.createAddress]
    };

    if (this.onCreate) {
      this.onCreate(dto);
    }
  }

  @computed('org')
  get initials() {
    if (!this.org) return '';
    if (this.org?.name.includes(' ')) {
      return this.org.name.charAt(0).toUpperCase() + this.org?.name.charAt(1).toUpperCase();
    } else {
      return this.org.name.charAt(0).toUpperCase();
    }
  }
}
