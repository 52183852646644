/* tslint:disable */
/* eslint-disable */
/**
 * Rest API Fimab
 * Rest-like API for Fimab Armario
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Address
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Address
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Address
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Address
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Address
     */
    'updatedDay': AddressUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Address
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Address
     */
    'createdDay': AddressCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Address
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'lastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    'isDefault': boolean;
    /**
     * 
     * @type {Organization}
     * @memberof Address
     */
    'organization': Organization;
}

export const AddressUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type AddressUpdatedDayEnum = typeof AddressUpdatedDayEnum[keyof typeof AddressUpdatedDayEnum];
export const AddressCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type AddressCreatedDayEnum = typeof AddressCreatedDayEnum[keyof typeof AddressCreatedDayEnum];

/**
 * 
 * @export
 * @interface AggregateCabinResult
 */
export interface AggregateCabinResult {
    /**
     * 
     * @type {string}
     * @memberof AggregateCabinResult
     */
    'cnt': string;
    /**
     * 
     * @type {number}
     * @memberof AggregateCabinResult
     */
    'cabin': number;
}
/**
 * 
 * @export
 * @interface AggregateCount
 */
export interface AggregateCount {
    /**
     * 
     * @type {number}
     * @memberof AggregateCount
     */
    'cnt': number;
    /**
     * 
     * @type {string}
     * @memberof AggregateCount
     */
    'createdDay': AggregateCountCreatedDayEnum;
    /**
     * 
     * @type {string}
     * @memberof AggregateCount
     */
    'created': string;
}

export const AggregateCountCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type AggregateCountCreatedDayEnum = typeof AggregateCountCreatedDayEnum[keyof typeof AggregateCountCreatedDayEnum];

/**
 * 
 * @export
 * @interface AggregateMaterialResult
 */
export interface AggregateMaterialResult {
    /**
     * 
     * @type {string}
     * @memberof AggregateMaterialResult
     */
    'cnt': string;
    /**
     * 
     * @type {string}
     * @memberof AggregateMaterialResult
     */
    'material': string;
    /**
     * 
     * @type {string}
     * @memberof AggregateMaterialResult
     */
    'finish': string;
}
/**
 * 
 * @export
 * @interface AggregateMigratedUsersCountResult
 */
export interface AggregateMigratedUsersCountResult {
    /**
     * 
     * @type {number}
     * @memberof AggregateMigratedUsersCountResult
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AggregateMigratedUsersCountResult
     */
    'migratedCount': number;
}
/**
 * 
 * @export
 * @interface AggregateOptionsResult
 */
export interface AggregateOptionsResult {
    /**
     * 
     * @type {number}
     * @memberof AggregateOptionsResult
     */
    'socketOptionsOffer': number;
    /**
     * 
     * @type {number}
     * @memberof AggregateOptionsResult
     */
    'socketOptionsOrder': number;
    /**
     * 
     * @type {number}
     * @memberof AggregateOptionsResult
     */
    'interiorHingesOptionsOffer': number;
    /**
     * 
     * @type {number}
     * @memberof AggregateOptionsResult
     */
    'interiorHingesOptionsOrder': number;
    /**
     * 
     * @type {number}
     * @memberof AggregateOptionsResult
     */
    'ceilingOptionsOffer': number;
    /**
     * 
     * @type {number}
     * @memberof AggregateOptionsResult
     */
    'ceilingOptionsOrder': number;
}
/**
 * 
 * @export
 * @interface AggregateResult
 */
export interface AggregateResult {
    /**
     * 
     * @type {Array<AggregateCount>}
     * @memberof AggregateResult
     */
    'aggr': Array<AggregateCount>;
    /**
     * 
     * @type {number}
     * @memberof AggregateResult
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface Assemblypart
 */
export interface Assemblypart {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Assemblypart
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Assemblypart
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Assemblypart
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Assemblypart
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Assemblypart
     */
    'updatedDay': AssemblypartUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Assemblypart
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Assemblypart
     */
    'createdDay': AssemblypartCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Assemblypart
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Assemblypart
     */
    'partId': string;
    /**
     * 
     * @type {string}
     * @memberof Assemblypart
     */
    'fimabId': string;
    /**
     * 
     * @type {string}
     * @memberof Assemblypart
     */
    'materialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Assemblypart
     */
    'wicamNr': string;
    /**
     * 
     * @type {string}
     * @memberof Assemblypart
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof Assemblypart
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Assemblypart
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'innerWidth': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'innerHeight': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'innerDepth': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'innerDiameter': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'outerWidth': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'outerHeight': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'outerDepth': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'outerDiameter': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'diameter': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof Assemblypart
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof Assemblypart
     */
    'side': string;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'angle': number;
    /**
     * 
     * @type {boolean}
     * @memberof Assemblypart
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'xPosition': number;
    /**
     * 
     * @type {number}
     * @memberof Assemblypart
     */
    'yPosition': number;
    /**
     * 
     * @type {Moduldefinition}
     * @memberof Assemblypart
     */
    'moduldefinition': Moduldefinition;
}

export const AssemblypartUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type AssemblypartUpdatedDayEnum = typeof AssemblypartUpdatedDayEnum[keyof typeof AssemblypartUpdatedDayEnum];
export const AssemblypartCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type AssemblypartCreatedDayEnum = typeof AssemblypartCreatedDayEnum[keyof typeof AssemblypartCreatedDayEnum];

/**
 * 
 * @export
 * @interface Cabinet
 */
export interface Cabinet {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Cabinet
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Cabinet
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Cabinet
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Cabinet
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Cabinet
     */
    'updatedDay': CabinetUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Cabinet
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Cabinet
     */
    'createdDay': CabinetCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Cabinet
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Cabinet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Cabinet
     */
    'category': string;
    /**
     * 
     * @type {number}
     * @memberof Cabinet
     */
    'minHeight': number;
    /**
     * 
     * @type {number}
     * @memberof Cabinet
     */
    'maxHeight': number;
    /**
     * 
     * @type {number}
     * @memberof Cabinet
     */
    'minWidth': number;
    /**
     * 
     * @type {number}
     * @memberof Cabinet
     */
    'maxWidth': number;
    /**
     * 
     * @type {number}
     * @memberof Cabinet
     */
    'minLength': number;
    /**
     * 
     * @type {number}
     * @memberof Cabinet
     */
    'maxLength': number;
    /**
     * 
     * @type {object}
     * @memberof Cabinet
     */
    'restrictedAreas': object;
    /**
     * 
     * @type {string}
     * @memberof Cabinet
     */
    'apolloId': string;
    /**
     * 
     * @type {any}
     * @memberof Cabinet
     */
    'model': any;
    /**
     * 
     * @type {Array<Order>}
     * @memberof Cabinet
     */
    'orders': Array<Order>;
}

export const CabinetUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type CabinetUpdatedDayEnum = typeof CabinetUpdatedDayEnum[keyof typeof CabinetUpdatedDayEnum];
export const CabinetCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type CabinetCreatedDayEnum = typeof CabinetCreatedDayEnum[keyof typeof CabinetCreatedDayEnum];

/**
 * 
 * @export
 * @interface CalculationOrder
 */
export interface CalculationOrder {
    /**
     * 
     * @type {ModuleDefinition}
     * @memberof CalculationOrder
     */
    'moduleDefinition': ModuleDefinition;
    /**
     * 
     * @type {Color}
     * @memberof CalculationOrder
     */
    'color': Color;
    /**
     * 
     * @type {boolean}
     * @memberof CalculationOrder
     */
    'output': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalculationOrder
     */
    'roundHTMLView': boolean;
    /**
     * 
     * @type {string}
     * @memberof CalculationOrder
     */
    'finish': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationOrder
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationOrder
     */
    'material': string;
    /**
     * 
     * @type {Cabinet}
     * @memberof CalculationOrder
     */
    'cabinet': Cabinet;
    /**
     * 
     * @type {number}
     * @memberof CalculationOrder
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof CalculationOrder
     */
    'variantName': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationOrder
     */
    'steelType': string;
}
/**
 * 
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * 
     * @type {string}
     * @memberof Color
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Color
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Color
     */
    'rgb_approx': string;
    /**
     * 
     * @type {string}
     * @memberof Color
     */
    'rgb_hex': string;
    /**
     * 
     * @type {boolean}
     * @memberof Color
     */
    'esd': boolean;
}
/**
 * 
 * @export
 * @interface Component
 */
export interface Component {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Component
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Component
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Component
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Component
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Component
     */
    'updatedDay': ComponentUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Component
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Component
     */
    'createdDay': ComponentCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Component
     */
    'deleted': string;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'fimabId': number;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'category': string;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'innerWidth': number;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'innerHeight': number;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'innerDepth': number;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'innerDiameter': number;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'outerWidth': number;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'outerHeight': number;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'outerDepth': number;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'outerDiameter': number;
    /**
     * 
     * @type {boolean}
     * @memberof Component
     */
    'isVisible': boolean;
    /**
     * 
     * @type {any}
     * @memberof Component
     */
    'models': any;
}

export const ComponentUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type ComponentUpdatedDayEnum = typeof ComponentUpdatedDayEnum[keyof typeof ComponentUpdatedDayEnum];
export const ComponentCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type ComponentCreatedDayEnum = typeof ComponentCreatedDayEnum[keyof typeof ComponentCreatedDayEnum];

/**
 * 
 * @export
 * @interface CreateAddressPayload
 */
export interface CreateAddressPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateAddressPayload
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressPayload
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressPayload
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressPayload
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressPayload
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressPayload
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressPayload
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface CreateDiscountPayload
 */
export interface CreateDiscountPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountPayload
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountPayload
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscountPayload
     */
    'amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDiscountPayload
     */
    'isPercentage': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountPayload
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscountPayload
     */
    'endDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDiscountPayload
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface CreateFilePayload
 */
export interface CreateFilePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateFilePayload
     */
    'key': string;
    /**
     * 
     * @type {object}
     * @memberof CreateFilePayload
     */
    'data': object;
    /**
     * 
     * @type {string}
     * @memberof CreateFilePayload
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFilePayload
     */
    'contentType': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFilePayload
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface CreateLayoutPayload
 */
export interface CreateLayoutPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateLayoutPayload
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof CreateLayoutPayload
     */
    'components': object;
}
/**
 * 
 * @export
 * @interface CreateOrderConfirmationPayload
 */
export interface CreateOrderConfirmationPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderConfirmationPayload
     */
    'targetMail': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderConfirmationPayload
     */
    'userText': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderConfirmationPayload
     */
    'pdfLink': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderConfirmationPayload
     */
    'orderLink': string;
}
/**
 * 
 * @export
 * @interface CreateOrderPayload
 */
export interface CreateOrderPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'ordernumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'internalOrdernumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderPayload
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'share'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'status'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateOrderPayload
     */
    'color'?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'finish'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'deliverydate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'requestDeliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPayload
     */
    'orderNote'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderPayload
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderPayload
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderPayload
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateOrderPayload
     */
    's3screens'?: object;
    /**
     * 
     * @type {Moduldefinition}
     * @memberof CreateOrderPayload
     */
    'moduldefinition'?: Moduldefinition;
    /**
     * 
     * @type {any}
     * @memberof CreateOrderPayload
     */
    'exportXml'?: any;
    /**
     * 
     * @type {User}
     * @memberof CreateOrderPayload
     */
    'user'?: User;
    /**
     * 
     * @type {Cabinet}
     * @memberof CreateOrderPayload
     */
    'cabinet'?: Cabinet;
}
/**
 * 
 * @export
 * @interface CreateOrganizationPayload
 */
export interface CreateOrganizationPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationPayload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationPayload
     */
    'taxId': string;
    /**
     * 
     * @type {Array<CreateAddressPayload>}
     * @memberof CreateOrganizationPayload
     */
    'addresses': Array<CreateAddressPayload>;
}
/**
 * 
 * @export
 * @interface CreatePriceconfigPayload
 */
export interface CreatePriceconfigPayload {
    /**
     * 
     * @type {string}
     * @memberof CreatePriceconfigPayload
     */
    'type': CreatePriceconfigPayloadTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof CreatePriceconfigPayload
     */
    'json': object;
}

export const CreatePriceconfigPayloadTypeEnum = {
    Materials: 'materials',
    Variants: 'variants',
    General: 'general'
} as const;

export type CreatePriceconfigPayloadTypeEnum = typeof CreatePriceconfigPayloadTypeEnum[keyof typeof CreatePriceconfigPayloadTypeEnum];

/**
 * 
 * @export
 * @interface CreateUserPayload
 */
export interface CreateUserPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateUserPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserPayload
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserPayload
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserPayload
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Discount
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Discount
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Discount
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Discount
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Discount
     */
    'updatedDay': DiscountUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Discount
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Discount
     */
    'createdDay': DiscountCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Discount
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    'amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    'isPercentage': boolean;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    'endDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    'isActive': boolean;
}

export const DiscountUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type DiscountUpdatedDayEnum = typeof DiscountUpdatedDayEnum[keyof typeof DiscountUpdatedDayEnum];
export const DiscountCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type DiscountCreatedDayEnum = typeof DiscountCreatedDayEnum[keyof typeof DiscountCreatedDayEnum];

/**
 * 
 * @export
 * @interface ForgotPassswordPayload
 */
export interface ForgotPassswordPayload {
    /**
     * 
     * @type {string}
     * @memberof ForgotPassswordPayload
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface HINTEN
 */
export interface HINTEN {
    /**
     * 
     * @type {string}
     * @memberof HINTEN
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof HINTEN
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof HINTEN
     */
    'minY': number;
    /**
     * 
     * @type {number}
     * @memberof HINTEN
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof HINTEN
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof HINTEN
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof HINTEN
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof HINTEN
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HINTEN
     */
    'components': Array<string>;
}
/**
 * 
 * @export
 * @interface LINKS
 */
export interface LINKS {
    /**
     * 
     * @type {string}
     * @memberof LINKS
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof LINKS
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof LINKS
     */
    'minY': number;
    /**
     * 
     * @type {number}
     * @memberof LINKS
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof LINKS
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof LINKS
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof LINKS
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof LINKS
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LINKS
     */
    'components': Array<string>;
}
/**
 * 
 * @export
 * @interface Layout
 */
export interface Layout {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Layout
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Layout
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Layout
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Layout
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Layout
     */
    'updatedDay': LayoutUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Layout
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Layout
     */
    'createdDay': LayoutCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Layout
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Layout
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof Layout
     */
    'components': object;
    /**
     * 
     * @type {User}
     * @memberof Layout
     */
    'user': User;
}

export const LayoutUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type LayoutUpdatedDayEnum = typeof LayoutUpdatedDayEnum[keyof typeof LayoutUpdatedDayEnum];
export const LayoutCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type LayoutCreatedDayEnum = typeof LayoutCreatedDayEnum[keyof typeof LayoutCreatedDayEnum];

/**
 * 
 * @export
 * @interface Log
 */
export interface Log {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Log
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Log
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Log
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Log
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Log
     */
    'updatedDay': LogUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Log
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Log
     */
    'createdDay': LogCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Log
     */
    'deleted': string;
    /**
     * 
     * @type {User}
     * @memberof Log
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'entityName': string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'operation': string;
}

export const LogUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type LogUpdatedDayEnum = typeof LogUpdatedDayEnum[keyof typeof LogUpdatedDayEnum];
export const LogCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type LogCreatedDayEnum = typeof LogCreatedDayEnum[keyof typeof LogCreatedDayEnum];

/**
 * 
 * @export
 * @interface LoginPayload
 */
export interface LoginPayload {
    /**
     * 
     * @type {string}
     * @memberof LoginPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginPayload
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'expiresIn': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'accessToken': string;
    /**
     * 
     * @type {User}
     * @memberof LoginResponse
     */
    'user': User;
}
/**
 * 
 * @export
 * @interface MONTAGEPLATTE
 */
export interface MONTAGEPLATTE {
    /**
     * 
     * @type {string}
     * @memberof MONTAGEPLATTE
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof MONTAGEPLATTE
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof MONTAGEPLATTE
     */
    'minY': number;
    /**
     * 
     * @type {number}
     * @memberof MONTAGEPLATTE
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof MONTAGEPLATTE
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof MONTAGEPLATTE
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof MONTAGEPLATTE
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof MONTAGEPLATTE
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MONTAGEPLATTE
     */
    'components': Array<string>;
}
/**
 * 
 * @export
 * @interface MigrationPayload
 */
export interface MigrationPayload {
    /**
     * 
     * @type {boolean}
     * @memberof MigrationPayload
     */
    'delta': boolean;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof ModelFile
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof ModelFile
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof ModelFile
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof ModelFile
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof ModelFile
     */
    'updatedDay': ModelFileUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof ModelFile
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof ModelFile
     */
    'createdDay': ModelFileCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof ModelFile
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'key': string;
    /**
     * 
     * @type {User}
     * @memberof ModelFile
     */
    'user': User;
    /**
     * 
     * @type {Cabinet}
     * @memberof ModelFile
     */
    'cabinet': Cabinet;
    /**
     * 
     * @type {Array<Component>}
     * @memberof ModelFile
     */
    'components': Array<Component>;
    /**
     * 
     * @type {Order}
     * @memberof ModelFile
     */
    'order': Order;
}

export const ModelFileUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type ModelFileUpdatedDayEnum = typeof ModelFileUpdatedDayEnum[keyof typeof ModelFileUpdatedDayEnum];
export const ModelFileCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type ModelFileCreatedDayEnum = typeof ModelFileCreatedDayEnum[keyof typeof ModelFileCreatedDayEnum];

/**
 * 
 * @export
 * @interface Moduldefinition
 */
export interface Moduldefinition {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Moduldefinition
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Moduldefinition
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Moduldefinition
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Moduldefinition
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Moduldefinition
     */
    'updatedDay': ModuldefinitionUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Moduldefinition
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Moduldefinition
     */
    'createdDay': ModuldefinitionCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Moduldefinition
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Moduldefinition
     */
    'length': string;
    /**
     * 
     * @type {string}
     * @memberof Moduldefinition
     */
    'width': string;
    /**
     * 
     * @type {string}
     * @memberof Moduldefinition
     */
    'height': string;
    /**
     * 
     * @type {string}
     * @memberof Moduldefinition
     */
    'socket': string;
    /**
     * 
     * @type {number}
     * @memberof Moduldefinition
     */
    'cabin': number;
    /**
     * 
     * @type {object}
     * @memberof Moduldefinition
     */
    'sides': object;
    /**
     * 
     * @type {string}
     * @memberof Moduldefinition
     */
    'modelKey': string;
    /**
     * 
     * @type {object}
     * @memberof Moduldefinition
     */
    'alternateShipping': object;
    /**
     * 
     * @type {object}
     * @memberof Moduldefinition
     */
    'alternatePayment': object;
    /**
     * 
     * @type {string}
     * @memberof Moduldefinition
     */
    'orderBuyer': string;
    /**
     * 
     * @type {number}
     * @memberof Moduldefinition
     */
    'currentStep': number;
    /**
     * 
     * @type {number}
     * @memberof Moduldefinition
     */
    'cutouts': number;
    /**
     * 
     * @type {object}
     * @memberof Moduldefinition
     */
    'meta': object;
    /**
     * 
     * @type {Order}
     * @memberof Moduldefinition
     */
    'order': Order;
    /**
     * 
     * @type {Array<Assemblypart>}
     * @memberof Moduldefinition
     */
    'assemblyparts': Array<Assemblypart>;
}

export const ModuldefinitionUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type ModuldefinitionUpdatedDayEnum = typeof ModuldefinitionUpdatedDayEnum[keyof typeof ModuldefinitionUpdatedDayEnum];
export const ModuldefinitionCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type ModuldefinitionCreatedDayEnum = typeof ModuldefinitionCreatedDayEnum[keyof typeof ModuldefinitionCreatedDayEnum];

/**
 * 
 * @export
 * @interface ModuleDefinition
 */
export interface ModuleDefinition {
    /**
     * 
     * @type {string}
     * @memberof ModuleDefinition
     */
    'Laenge': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleDefinition
     */
    'Breite': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleDefinition
     */
    'Hoehe': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleDefinition
     */
    'Sockel': string;
    /**
     * 
     * @type {number}
     * @memberof ModuleDefinition
     */
    'Cabin': number;
    /**
     * 
     * @type {Sides}
     * @memberof ModuleDefinition
     */
    'sides': Sides;
    /**
     * 
     * @type {number}
     * @memberof ModuleDefinition
     */
    'ausschnitte': number;
    /**
     * 
     * @type {number}
     * @memberof ModuleDefinition
     */
    'currentStep': number;
}
/**
 * 
 * @export
 * @interface MyOrderResult
 */
export interface MyOrderResult {
    /**
     * 
     * @type {Array<Order>}
     * @memberof MyOrderResult
     */
    'orders': Array<Order>;
    /**
     * 
     * @type {number}
     * @memberof MyOrderResult
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface OBEN
 */
export interface OBEN {
    /**
     * 
     * @type {string}
     * @memberof OBEN
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof OBEN
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof OBEN
     */
    'minY': number;
    /**
     * 
     * @type {number}
     * @memberof OBEN
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof OBEN
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof OBEN
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof OBEN
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof OBEN
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OBEN
     */
    'components': Array<string>;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Order
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Order
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Order
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Order
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Order
     */
    'updatedDay': OrderUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Order
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Order
     */
    'createdDay': OrderCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Order
     */
    'deleted': string;
    /**
     * Name of the order. Input by the user.
     * @type {string}
     * @memberof Order
     */
    'name': string;
    /**
     * List of user IDs that are able to see this order. If empty, only the user who created the order can see it
     * @type {Array<string>}
     * @memberof Order
     */
    'approvals': Array<string>;
    /**
     * Order number. Automatically incremented by the system.
     * @type {string}
     * @memberof Order
     */
    'ordernumber': string;
    /**
     * Internal order number. Input by the user.
     * @type {string}
     * @memberof Order
     */
    'internalOrdernumber': string;
    /**
     * Amount of the order. Default is 1.
     * @type {number}
     * @memberof Order
     */
    'amount': number;
    /**
     * Share of the order. UID generated by the system and used to share with a deeplink
     * @type {string}
     * @memberof Order
     */
    'share': string;
    /**
     * Status of the order
     * @type {string}
     * @memberof Order
     */
    'status': OrderStatusEnum;
    /**
     * Production status of the order. Default is \"NOT_STARTED\".
     * @type {string}
     * @memberof Order
     */
    'productionStatus': OrderProductionStatusEnum;
    /**
     * Color of the order stored as a JSON object
     * @type {object}
     * @memberof Order
     */
    'color': object;
    /**
     * Material finish type. Can be \"Grobstruktur glänzend\", \"Glatt glänzend\" or null
     * @type {string}
     * @memberof Order
     */
    'finish': string;
    /**
     * Material type. Can be \"Edelstahl\", \"Stahlblech\" or null
     * @type {string}
     * @memberof Order
     */
    'material': string;
    /**
     * Delivery date of the order. Default is an empty string.
     * @type {string}
     * @memberof Order
     */
    'deliverydate': string;
    /**
     * Request delivery date of the order. Input by the user if he wishes a later delivery date than normal
     * @type {string}
     * @memberof Order
     */
    'requestDeliveryDate': string;
    /**
     * Order note. Input by the user
     * @type {string}
     * @memberof Order
     */
    'orderNote': string;
    /**
     * Public status of the order
     * @type {boolean}
     * @memberof Order
     */
    'isPublic': boolean;
    /**
     * Calculated price of the order
     * @type {number}
     * @memberof Order
     */
    'price': number;
    /**
     * Calculated price of the order without discounts
     * @type {number}
     * @memberof Order
     */
    'strikePrice': number;
    /**
     * If this order is soft deleted by the user. Depcrecated. Use deleted timestamp instead. Default is false.
     * @type {boolean}
     * @memberof Order
     */
    'isDeleted': boolean;
    /**
     * Screenshots of the order stored in S3
     * @type {object}
     * @memberof Order
     */
    's3screens': object;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    'ceilingOption': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    'socketOption': boolean;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'socketHeightOption': number;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    'interiorHingesOption': boolean;
    /**
     * Order code. Automatically generated by the system and used in Apollo
     * @type {string}
     * @memberof Order
     */
    'orderCode': string;
    /**
     * 
     * @type {OrderModuldefinition}
     * @memberof Order
     */
    'moduldefinition': OrderModuldefinition;
    /**
     * 
     * @type {OrderExportXml}
     * @memberof Order
     */
    'exportXml': OrderExportXml;
    /**
     * 
     * @type {OrderUser}
     * @memberof Order
     */
    'user': OrderUser;
    /**
     * List of users who have access to the order and are considered owners
     * @type {Array<User>}
     * @memberof Order
     */
    'shared': Array<User>;
    /**
     * 
     * @type {OrderCabinet}
     * @memberof Order
     */
    'cabinet': OrderCabinet;
}

export const OrderUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderUpdatedDayEnum = typeof OrderUpdatedDayEnum[keyof typeof OrderUpdatedDayEnum];
export const OrderCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderCreatedDayEnum = typeof OrderCreatedDayEnum[keyof typeof OrderCreatedDayEnum];
export const OrderStatusEnum = {
    Neu: 'NEU',
    Ordered: 'ORDERED'
} as const;

export type OrderStatusEnum = typeof OrderStatusEnum[keyof typeof OrderStatusEnum];
export const OrderProductionStatusEnum = {
    NotStarted: 'not_started',
    InProgress: 'in_progress',
    Finished: 'finished',
    Delivered: 'delivered'
} as const;

export type OrderProductionStatusEnum = typeof OrderProductionStatusEnum[keyof typeof OrderProductionStatusEnum];

/**
 * Base Cabinet of the order (Static Entity)
 * @export
 * @interface OrderCabinet
 */
export interface OrderCabinet {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof OrderCabinet
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof OrderCabinet
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof OrderCabinet
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof OrderCabinet
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof OrderCabinet
     */
    'updatedDay': OrderCabinetUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof OrderCabinet
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof OrderCabinet
     */
    'createdDay': OrderCabinetCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof OrderCabinet
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCabinet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCabinet
     */
    'category': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCabinet
     */
    'minHeight': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCabinet
     */
    'maxHeight': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCabinet
     */
    'minWidth': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCabinet
     */
    'maxWidth': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCabinet
     */
    'minLength': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCabinet
     */
    'maxLength': number;
    /**
     * 
     * @type {object}
     * @memberof OrderCabinet
     */
    'restrictedAreas': object;
    /**
     * 
     * @type {string}
     * @memberof OrderCabinet
     */
    'apolloId': string;
    /**
     * 
     * @type {any}
     * @memberof OrderCabinet
     */
    'model': any;
    /**
     * 
     * @type {Array<Order>}
     * @memberof OrderCabinet
     */
    'orders': Array<Order>;
}

export const OrderCabinetUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderCabinetUpdatedDayEnum = typeof OrderCabinetUpdatedDayEnum[keyof typeof OrderCabinetUpdatedDayEnum];
export const OrderCabinetCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderCabinetCreatedDayEnum = typeof OrderCabinetCreatedDayEnum[keyof typeof OrderCabinetCreatedDayEnum];

/**
 * Apollo XML file of the order
 * @export
 * @interface OrderExportXml
 */
export interface OrderExportXml {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof OrderExportXml
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof OrderExportXml
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof OrderExportXml
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof OrderExportXml
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof OrderExportXml
     */
    'updatedDay': OrderExportXmlUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof OrderExportXml
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof OrderExportXml
     */
    'createdDay': OrderExportXmlCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof OrderExportXml
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof OrderExportXml
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderExportXml
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof OrderExportXml
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof OrderExportXml
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof OrderExportXml
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof OrderExportXml
     */
    'key': string;
    /**
     * 
     * @type {User}
     * @memberof OrderExportXml
     */
    'user': User;
    /**
     * 
     * @type {Cabinet}
     * @memberof OrderExportXml
     */
    'cabinet': Cabinet;
    /**
     * 
     * @type {Array<Component>}
     * @memberof OrderExportXml
     */
    'components': Array<Component>;
    /**
     * 
     * @type {Order}
     * @memberof OrderExportXml
     */
    'order': Order;
}

export const OrderExportXmlUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderExportXmlUpdatedDayEnum = typeof OrderExportXmlUpdatedDayEnum[keyof typeof OrderExportXmlUpdatedDayEnum];
export const OrderExportXmlCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderExportXmlCreatedDayEnum = typeof OrderExportXmlCreatedDayEnum[keyof typeof OrderExportXmlCreatedDayEnum];

/**
 * Moduldefinition of the order containing information about the configured System
 * @export
 * @interface OrderModuldefinition
 */
export interface OrderModuldefinition {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'updatedDay': OrderModuldefinitionUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'createdDay': OrderModuldefinitionCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'length': string;
    /**
     * 
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'width': string;
    /**
     * 
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'height': string;
    /**
     * 
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'socket': string;
    /**
     * 
     * @type {number}
     * @memberof OrderModuldefinition
     */
    'cabin': number;
    /**
     * 
     * @type {object}
     * @memberof OrderModuldefinition
     */
    'sides': object;
    /**
     * 
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'modelKey': string;
    /**
     * 
     * @type {object}
     * @memberof OrderModuldefinition
     */
    'alternateShipping': object;
    /**
     * 
     * @type {object}
     * @memberof OrderModuldefinition
     */
    'alternatePayment': object;
    /**
     * 
     * @type {string}
     * @memberof OrderModuldefinition
     */
    'orderBuyer': string;
    /**
     * 
     * @type {number}
     * @memberof OrderModuldefinition
     */
    'currentStep': number;
    /**
     * 
     * @type {number}
     * @memberof OrderModuldefinition
     */
    'cutouts': number;
    /**
     * 
     * @type {object}
     * @memberof OrderModuldefinition
     */
    'meta': object;
    /**
     * 
     * @type {Order}
     * @memberof OrderModuldefinition
     */
    'order': Order;
    /**
     * 
     * @type {Array<Assemblypart>}
     * @memberof OrderModuldefinition
     */
    'assemblyparts': Array<Assemblypart>;
}

export const OrderModuldefinitionUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderModuldefinitionUpdatedDayEnum = typeof OrderModuldefinitionUpdatedDayEnum[keyof typeof OrderModuldefinitionUpdatedDayEnum];
export const OrderModuldefinitionCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderModuldefinitionCreatedDayEnum = typeof OrderModuldefinitionCreatedDayEnum[keyof typeof OrderModuldefinitionCreatedDayEnum];

/**
 * User who created and owns the order
 * @export
 * @interface OrderUser
 */
export interface OrderUser {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof OrderUser
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof OrderUser
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof OrderUser
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof OrderUser
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof OrderUser
     */
    'updatedDay': OrderUserUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof OrderUser
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof OrderUser
     */
    'createdDay': OrderUserCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof OrderUser
     */
    'deleted': string;
    /**
     * ID automaticly incremented by the System and manually updated by an Admin if necessary
     * @type {number}
     * @memberof OrderUser
     */
    'customerId': number;
    /**
     * Cognito User ID - OLD SYSTEM
     * @type {string}
     * @memberof OrderUser
     * @deprecated
     */
    'cognitoUser': string;
    /**
     * User role
     * @type {string}
     * @memberof OrderUser
     */
    'role': OrderUserRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OrderUser
     * @deprecated
     */
    'privacy': boolean;
    /**
     * Salutation of the user
     * @type {string}
     * @memberof OrderUser
     */
    'salutation': string;
    /**
     * Address of the user
     * @type {string}
     * @memberof OrderUser
     */
    'address': string;
    /**
     * Department of the user
     * @type {string}
     * @memberof OrderUser
     */
    'department': string;
    /**
     * Phone number of the user
     * @type {string}
     * @memberof OrderUser
     */
    'phone': string;
    /**
     * Payment terms of the user. Set by admins
     * @type {string}
     * @memberof OrderUser
     */
    'payment': string;
    /**
     * Shipping terms of the user. Set by admins
     * @type {string}
     * @memberof OrderUser
     */
    'shipping': string;
    /**
     * Incoterm of the user. Set by admins
     * @type {string}
     * @memberof OrderUser
     */
    'incoterm': string;
    /**
     * Title of the user
     * @type {string}
     * @memberof OrderUser
     */
    'title': string;
    /**
     * First name of the user
     * @type {string}
     * @memberof OrderUser
     */
    'firstName': string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof OrderUser
     */
    'lastName': string;
    /**
     * Email of the user
     * @type {string}
     * @memberof OrderUser
     */
    'email': string;
    /**
     * City of the user
     * @type {string}
     * @memberof OrderUser
     */
    'city': string;
    /**
     * Zip code of the user
     * @type {string}
     * @memberof OrderUser
     */
    'zip': string;
    /**
     * Country of the user
     * @type {string}
     * @memberof OrderUser
     */
    'country': string;
    /**
     * Tax ID of the user
     * @type {string}
     * @memberof OrderUser
     */
    'taxId': string;
    /**
     * 
     * @type {UserOrders}
     * @memberof OrderUser
     */
    'orders': UserOrders;
    /**
     * Shared orders of the user from other users
     * @type {Array<Order>}
     * @memberof OrderUser
     */
    'shared': Array<Order>;
    /**
     * 
     * @type {UserAvatar}
     * @memberof OrderUser
     */
    'avatar': UserAvatar;
    /**
     * Organization the user is invited to. WIP
     * @type {Array<OrganizationInvite>}
     * @memberof OrderUser
     */
    'invites': Array<OrganizationInvite>;
    /**
     * 
     * @type {UserOrganization}
     * @memberof OrderUser
     */
    'organization': UserOrganization;
    /**
     * 
     * @type {string}
     * @memberof OrderUser
     */
    'organizationId': string;
    /**
     * 
     * @type {Array<Log>}
     * @memberof OrderUser
     */
    'logs': Array<Log>;
    /**
     * 
     * @type {Array<Layout>}
     * @memberof OrderUser
     */
    'layouts': Array<Layout>;
    /**
     * Locked users can\'t login
     * @type {boolean}
     * @memberof OrderUser
     */
    'locked': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderUser
     * @deprecated
     */
    'company': string;
}

export const OrderUserUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderUserUpdatedDayEnum = typeof OrderUserUpdatedDayEnum[keyof typeof OrderUserUpdatedDayEnum];
export const OrderUserCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrderUserCreatedDayEnum = typeof OrderUserCreatedDayEnum[keyof typeof OrderUserCreatedDayEnum];
export const OrderUserRoleEnum = {
    Default: 'default',
    Advanced: 'advanced',
    Admin: 'admin'
} as const;

export type OrderUserRoleEnum = typeof OrderUserRoleEnum[keyof typeof OrderUserRoleEnum];

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Organization
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Organization
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Organization
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Organization
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Organization
     */
    'updatedDay': OrganizationUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Organization
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Organization
     */
    'createdDay': OrganizationCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Organization
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'taxId': string;
    /**
     * 
     * @type {Array<OrganizationInvite>}
     * @memberof Organization
     */
    'invites': Array<OrganizationInvite>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Organization
     */
    'users': Array<User>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Organization
     */
    'addresses': Array<Address>;
}

export const OrganizationUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrganizationUpdatedDayEnum = typeof OrganizationUpdatedDayEnum[keyof typeof OrganizationUpdatedDayEnum];
export const OrganizationCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrganizationCreatedDayEnum = typeof OrganizationCreatedDayEnum[keyof typeof OrganizationCreatedDayEnum];

/**
 * 
 * @export
 * @interface OrganizationInvite
 */
export interface OrganizationInvite {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof OrganizationInvite
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof OrganizationInvite
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof OrganizationInvite
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof OrganizationInvite
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof OrganizationInvite
     */
    'updatedDay': OrganizationInviteUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof OrganizationInvite
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof OrganizationInvite
     */
    'createdDay': OrganizationInviteCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof OrganizationInvite
     */
    'deleted': string;
    /**
     * 
     * @type {User}
     * @memberof OrganizationInvite
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvite
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvite
     */
    'initiator': string;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationInvite
     */
    'organization': Organization;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvite
     */
    'reason': OrganizationInviteReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvite
     */
    'status': OrganizationInviteStatusEnum;
}

export const OrganizationInviteUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrganizationInviteUpdatedDayEnum = typeof OrganizationInviteUpdatedDayEnum[keyof typeof OrganizationInviteUpdatedDayEnum];
export const OrganizationInviteCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type OrganizationInviteCreatedDayEnum = typeof OrganizationInviteCreatedDayEnum[keyof typeof OrganizationInviteCreatedDayEnum];
export const OrganizationInviteReasonEnum = {
    Register: 'register',
    Initial: 'initial',
    Invite: 'invite'
} as const;

export type OrganizationInviteReasonEnum = typeof OrganizationInviteReasonEnum[keyof typeof OrganizationInviteReasonEnum];
export const OrganizationInviteStatusEnum = {
    Pending: 'pending',
    Rejected: 'rejected',
    Accepted: 'accepted'
} as const;

export type OrganizationInviteStatusEnum = typeof OrganizationInviteStatusEnum[keyof typeof OrganizationInviteStatusEnum];

/**
 * 
 * @export
 * @interface Priceconfig
 */
export interface Priceconfig {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof Priceconfig
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof Priceconfig
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof Priceconfig
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof Priceconfig
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof Priceconfig
     */
    'updatedDay': PriceconfigUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof Priceconfig
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof Priceconfig
     */
    'createdDay': PriceconfigCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof Priceconfig
     */
    'deleted': string;
    /**
     * 
     * @type {object}
     * @memberof Priceconfig
     */
    'json': object;
    /**
     * 
     * @type {string}
     * @memberof Priceconfig
     */
    'type': PriceconfigTypeEnum;
}

export const PriceconfigUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type PriceconfigUpdatedDayEnum = typeof PriceconfigUpdatedDayEnum[keyof typeof PriceconfigUpdatedDayEnum];
export const PriceconfigCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type PriceconfigCreatedDayEnum = typeof PriceconfigCreatedDayEnum[keyof typeof PriceconfigCreatedDayEnum];
export const PriceconfigTypeEnum = {
    Materials: 'materials',
    Variants: 'variants',
    General: 'general'
} as const;

export type PriceconfigTypeEnum = typeof PriceconfigTypeEnum[keyof typeof PriceconfigTypeEnum];

/**
 * 
 * @export
 * @interface RECHTS
 */
export interface RECHTS {
    /**
     * 
     * @type {string}
     * @memberof RECHTS
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof RECHTS
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof RECHTS
     */
    'minY': number;
    /**
     * 
     * @type {number}
     * @memberof RECHTS
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof RECHTS
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof RECHTS
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof RECHTS
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof RECHTS
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RECHTS
     */
    'components': Array<string>;
}
/**
 * 
 * @export
 * @interface RegisterPayload
 */
export interface RegisterPayload {
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'passwordConfirmation': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'taxId': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPayload
     */
    'company': string;
}
/**
 * 
 * @export
 * @interface ResetPassswordPayload
 */
export interface ResetPassswordPayload {
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordPayload
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordPayload
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordPayload
     */
    'passwordConfirmation': string;
}
/**
 * 
 * @export
 * @interface SearchPayload
 */
export interface SearchPayload {
    /**
     * 
     * @type {number}
     * @memberof SearchPayload
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof SearchPayload
     */
    'limit': number;
    /**
     * 
     * @type {object}
     * @memberof SearchPayload
     */
    'order': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchPayload
     */
    'filter': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchPayload
     */
    'filterAnd': Array<string>;
}
/**
 * 
 * @export
 * @interface ShareBody
 */
export interface ShareBody {
    /**
     * 
     * @type {string}
     * @memberof ShareBody
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Sides
 */
export interface Sides {
    /**
     * 
     * @type {VORNE}
     * @memberof Sides
     */
    'VORNE': VORNE;
    /**
     * 
     * @type {HINTEN}
     * @memberof Sides
     */
    'HINTEN': HINTEN;
    /**
     * 
     * @type {MONTAGEPLATTE}
     * @memberof Sides
     */
    'MONTAGEPLATTE': MONTAGEPLATTE;
    /**
     * 
     * @type {LINKS}
     * @memberof Sides
     */
    'LINKS': LINKS;
    /**
     * 
     * @type {RECHTS}
     * @memberof Sides
     */
    'RECHTS': RECHTS;
    /**
     * 
     * @type {OBEN}
     * @memberof Sides
     */
    'OBEN': OBEN;
    /**
     * 
     * @type {UNTEN}
     * @memberof Sides
     */
    'UNTEN': UNTEN;
}
/**
 * 
 * @export
 * @interface UNTEN
 */
export interface UNTEN {
    /**
     * 
     * @type {string}
     * @memberof UNTEN
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof UNTEN
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof UNTEN
     */
    'minY': number;
    /**
     * 
     * @type {number}
     * @memberof UNTEN
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof UNTEN
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof UNTEN
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof UNTEN
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof UNTEN
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UNTEN
     */
    'components': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateAddressPayload
 */
export interface UpdateAddressPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressPayload
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDiscountPayload
 */
export interface UpdateDiscountPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountPayload
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountPayload
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDiscountPayload
     */
    'amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDiscountPayload
     */
    'isPercentage': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountPayload
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountPayload
     */
    'endDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDiscountPayload
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscountPayload
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UpdateOrderPayload
 */
export interface UpdateOrderPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'ordernumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'internalOrdernumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderPayload
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'share'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'status'?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateOrderPayload
     */
    'color'?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'finish'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'deliverydate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'requestDeliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'orderNote'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrderPayload
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderPayload
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrderPayload
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateOrderPayload
     */
    's3screens'?: object;
    /**
     * 
     * @type {Moduldefinition}
     * @memberof UpdateOrderPayload
     */
    'moduldefinition'?: Moduldefinition;
    /**
     * 
     * @type {any}
     * @memberof UpdateOrderPayload
     */
    'exportXml'?: any;
    /**
     * 
     * @type {User}
     * @memberof UpdateOrderPayload
     */
    'user'?: User;
    /**
     * 
     * @type {Cabinet}
     * @memberof UpdateOrderPayload
     */
    'cabinet'?: Cabinet;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPayload
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOrderPayload
     */
    'approvals'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationPayload
 */
export interface UpdateOrganizationPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationPayload
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateUserPayload
 */
export interface UpdateUserPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'city'?: string;
    /**
     * 
     * @type {any}
     * @memberof UpdateUserPayload
     */
    'avatar'?: any;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'payment'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserPayload
     */
    'privacy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'salutation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'shipping'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'taxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'zip'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof User
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof User
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof User
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof User
     */
    'updatedDay': UserUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof User
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof User
     */
    'createdDay': UserCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof User
     */
    'deleted': string;
    /**
     * ID automaticly incremented by the System and manually updated by an Admin if necessary
     * @type {number}
     * @memberof User
     */
    'customerId': number;
    /**
     * Cognito User ID - OLD SYSTEM
     * @type {string}
     * @memberof User
     * @deprecated
     */
    'cognitoUser': string;
    /**
     * User role
     * @type {string}
     * @memberof User
     */
    'role': UserRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof User
     * @deprecated
     */
    'privacy': boolean;
    /**
     * Salutation of the user
     * @type {string}
     * @memberof User
     */
    'salutation': string;
    /**
     * Address of the user
     * @type {string}
     * @memberof User
     */
    'address': string;
    /**
     * Department of the user
     * @type {string}
     * @memberof User
     */
    'department': string;
    /**
     * Phone number of the user
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * Payment terms of the user. Set by admins
     * @type {string}
     * @memberof User
     */
    'payment': string;
    /**
     * Shipping terms of the user. Set by admins
     * @type {string}
     * @memberof User
     */
    'shipping': string;
    /**
     * Incoterm of the user. Set by admins
     * @type {string}
     * @memberof User
     */
    'incoterm': string;
    /**
     * Title of the user
     * @type {string}
     * @memberof User
     */
    'title': string;
    /**
     * First name of the user
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * Email of the user
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * City of the user
     * @type {string}
     * @memberof User
     */
    'city': string;
    /**
     * Zip code of the user
     * @type {string}
     * @memberof User
     */
    'zip': string;
    /**
     * Country of the user
     * @type {string}
     * @memberof User
     */
    'country': string;
    /**
     * Tax ID of the user
     * @type {string}
     * @memberof User
     */
    'taxId': string;
    /**
     * 
     * @type {UserOrders}
     * @memberof User
     */
    'orders': UserOrders;
    /**
     * Shared orders of the user from other users
     * @type {Array<Order>}
     * @memberof User
     */
    'shared': Array<Order>;
    /**
     * 
     * @type {UserAvatar}
     * @memberof User
     */
    'avatar': UserAvatar;
    /**
     * Organization the user is invited to. WIP
     * @type {Array<OrganizationInvite>}
     * @memberof User
     */
    'invites': Array<OrganizationInvite>;
    /**
     * 
     * @type {UserOrganization}
     * @memberof User
     */
    'organization': UserOrganization;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'organizationId': string;
    /**
     * 
     * @type {Array<Log>}
     * @memberof User
     */
    'logs': Array<Log>;
    /**
     * 
     * @type {Array<Layout>}
     * @memberof User
     */
    'layouts': Array<Layout>;
    /**
     * Locked users can\'t login
     * @type {boolean}
     * @memberof User
     */
    'locked': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     * @deprecated
     */
    'company': string;
}

export const UserUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type UserUpdatedDayEnum = typeof UserUpdatedDayEnum[keyof typeof UserUpdatedDayEnum];
export const UserCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type UserCreatedDayEnum = typeof UserCreatedDayEnum[keyof typeof UserCreatedDayEnum];
export const UserRoleEnum = {
    Default: 'default',
    Advanced: 'advanced',
    Admin: 'admin'
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];

/**
 * Avatar of the user
 * @export
 * @interface UserAvatar
 */
export interface UserAvatar {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof UserAvatar
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof UserAvatar
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof UserAvatar
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof UserAvatar
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof UserAvatar
     */
    'updatedDay': UserAvatarUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof UserAvatar
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof UserAvatar
     */
    'createdDay': UserAvatarCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof UserAvatar
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof UserAvatar
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserAvatar
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof UserAvatar
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof UserAvatar
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof UserAvatar
     */
    'level': string;
    /**
     * 
     * @type {string}
     * @memberof UserAvatar
     */
    'key': string;
    /**
     * 
     * @type {User}
     * @memberof UserAvatar
     */
    'user': User;
    /**
     * 
     * @type {Cabinet}
     * @memberof UserAvatar
     */
    'cabinet': Cabinet;
    /**
     * 
     * @type {Array<Component>}
     * @memberof UserAvatar
     */
    'components': Array<Component>;
    /**
     * 
     * @type {Order}
     * @memberof UserAvatar
     */
    'order': Order;
}

export const UserAvatarUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type UserAvatarUpdatedDayEnum = typeof UserAvatarUpdatedDayEnum[keyof typeof UserAvatarUpdatedDayEnum];
export const UserAvatarCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type UserAvatarCreatedDayEnum = typeof UserAvatarCreatedDayEnum[keyof typeof UserAvatarCreatedDayEnum];

/**
 * Orders of the user
 * @export
 * @interface UserOrders
 */
export interface UserOrders {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof UserOrders
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof UserOrders
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof UserOrders
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof UserOrders
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof UserOrders
     */
    'updatedDay': UserOrdersUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof UserOrders
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof UserOrders
     */
    'createdDay': UserOrdersCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof UserOrders
     */
    'deleted': string;
    /**
     * Name of the order. Input by the user.
     * @type {string}
     * @memberof UserOrders
     */
    'name': string;
    /**
     * List of user IDs that are able to see this order. If empty, only the user who created the order can see it
     * @type {Array<string>}
     * @memberof UserOrders
     */
    'approvals': Array<string>;
    /**
     * Order number. Automatically incremented by the system.
     * @type {string}
     * @memberof UserOrders
     */
    'ordernumber': string;
    /**
     * Internal order number. Input by the user.
     * @type {string}
     * @memberof UserOrders
     */
    'internalOrdernumber': string;
    /**
     * Amount of the order. Default is 1.
     * @type {number}
     * @memberof UserOrders
     */
    'amount': number;
    /**
     * Share of the order. UID generated by the system and used to share with a deeplink
     * @type {string}
     * @memberof UserOrders
     */
    'share': string;
    /**
     * Status of the order
     * @type {string}
     * @memberof UserOrders
     */
    'status': UserOrdersStatusEnum;
    /**
     * Production status of the order. Default is \"NOT_STARTED\".
     * @type {string}
     * @memberof UserOrders
     */
    'productionStatus': UserOrdersProductionStatusEnum;
    /**
     * Color of the order stored as a JSON object
     * @type {object}
     * @memberof UserOrders
     */
    'color': object;
    /**
     * Material finish type. Can be \"Grobstruktur glänzend\", \"Glatt glänzend\" or null
     * @type {string}
     * @memberof UserOrders
     */
    'finish': string;
    /**
     * Material type. Can be \"Edelstahl\", \"Stahlblech\" or null
     * @type {string}
     * @memberof UserOrders
     */
    'material': string;
    /**
     * Delivery date of the order. Default is an empty string.
     * @type {string}
     * @memberof UserOrders
     */
    'deliverydate': string;
    /**
     * Request delivery date of the order. Input by the user if he wishes a later delivery date than normal
     * @type {string}
     * @memberof UserOrders
     */
    'requestDeliveryDate': string;
    /**
     * Order note. Input by the user
     * @type {string}
     * @memberof UserOrders
     */
    'orderNote': string;
    /**
     * Public status of the order
     * @type {boolean}
     * @memberof UserOrders
     */
    'isPublic': boolean;
    /**
     * Calculated price of the order
     * @type {number}
     * @memberof UserOrders
     */
    'price': number;
    /**
     * Calculated price of the order without discounts
     * @type {number}
     * @memberof UserOrders
     */
    'strikePrice': number;
    /**
     * If this order is soft deleted by the user. Depcrecated. Use deleted timestamp instead. Default is false.
     * @type {boolean}
     * @memberof UserOrders
     */
    'isDeleted': boolean;
    /**
     * Screenshots of the order stored in S3
     * @type {object}
     * @memberof UserOrders
     */
    's3screens': object;
    /**
     * 
     * @type {boolean}
     * @memberof UserOrders
     */
    'ceilingOption': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOrders
     */
    'socketOption': boolean;
    /**
     * 
     * @type {number}
     * @memberof UserOrders
     */
    'socketHeightOption': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserOrders
     */
    'interiorHingesOption': boolean;
    /**
     * Order code. Automatically generated by the system and used in Apollo
     * @type {string}
     * @memberof UserOrders
     */
    'orderCode': string;
    /**
     * 
     * @type {OrderModuldefinition}
     * @memberof UserOrders
     */
    'moduldefinition': OrderModuldefinition;
    /**
     * 
     * @type {OrderExportXml}
     * @memberof UserOrders
     */
    'exportXml': OrderExportXml;
    /**
     * 
     * @type {OrderUser}
     * @memberof UserOrders
     */
    'user': OrderUser;
    /**
     * List of users who have access to the order and are considered owners
     * @type {Array<User>}
     * @memberof UserOrders
     */
    'shared': Array<User>;
    /**
     * 
     * @type {OrderCabinet}
     * @memberof UserOrders
     */
    'cabinet': OrderCabinet;
}

export const UserOrdersUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type UserOrdersUpdatedDayEnum = typeof UserOrdersUpdatedDayEnum[keyof typeof UserOrdersUpdatedDayEnum];
export const UserOrdersCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type UserOrdersCreatedDayEnum = typeof UserOrdersCreatedDayEnum[keyof typeof UserOrdersCreatedDayEnum];
export const UserOrdersStatusEnum = {
    Neu: 'NEU',
    Ordered: 'ORDERED'
} as const;

export type UserOrdersStatusEnum = typeof UserOrdersStatusEnum[keyof typeof UserOrdersStatusEnum];
export const UserOrdersProductionStatusEnum = {
    NotStarted: 'not_started',
    InProgress: 'in_progress',
    Finished: 'finished',
    Delivered: 'delivered'
} as const;

export type UserOrdersProductionStatusEnum = typeof UserOrdersProductionStatusEnum[keyof typeof UserOrdersProductionStatusEnum];

/**
 * Organization the user is part of. WIP
 * @export
 * @interface UserOrganization
 */
export interface UserOrganization {
    /**
     * UUID of the Entity
     * @type {string}
     * @memberof UserOrganization
     */
    'id': string;
    /**
     * ID of the Hubspot Entity if connected
     * @type {string}
     * @memberof UserOrganization
     */
    'hubspotId': string;
    /**
     * ID of the Migration from the old system
     * @type {string}
     * @memberof UserOrganization
     */
    'migrationId': string;
    /**
     * Date of the last update
     * @type {string}
     * @memberof UserOrganization
     */
    'updated': string;
    /**
     * Weekday of the last update
     * @type {string}
     * @memberof UserOrganization
     */
    'updatedDay': UserOrganizationUpdatedDayEnum;
    /**
     * Date of the creation
     * @type {string}
     * @memberof UserOrganization
     */
    'created': string;
    /**
     * Weekday of the creation
     * @type {string}
     * @memberof UserOrganization
     */
    'createdDay': UserOrganizationCreatedDayEnum;
    /**
     * Date of the deletion. This is for soft deletes
     * @type {string}
     * @memberof UserOrganization
     */
    'deleted': string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'taxId': string;
    /**
     * 
     * @type {Array<OrganizationInvite>}
     * @memberof UserOrganization
     */
    'invites': Array<OrganizationInvite>;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserOrganization
     */
    'users': Array<User>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof UserOrganization
     */
    'addresses': Array<Address>;
}

export const UserOrganizationUpdatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type UserOrganizationUpdatedDayEnum = typeof UserOrganizationUpdatedDayEnum[keyof typeof UserOrganizationUpdatedDayEnum];
export const UserOrganizationCreatedDayEnum = {
    Sonntag: 'Sonntag',
    Montag: 'Montag',
    Dienstag: 'Dienstag',
    Mittwoch: 'Mittwoch',
    Donnerstag: 'Donnerstag',
    Freitag: 'Freitag',
    Samstag: 'Samstag'
} as const;

export type UserOrganizationCreatedDayEnum = typeof UserOrganizationCreatedDayEnum[keyof typeof UserOrganizationCreatedDayEnum];

/**
 * 
 * @export
 * @interface VORNE
 */
export interface VORNE {
    /**
     * 
     * @type {string}
     * @memberof VORNE
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof VORNE
     */
    'minX': number;
    /**
     * 
     * @type {number}
     * @memberof VORNE
     */
    'minY': number;
    /**
     * 
     * @type {number}
     * @memberof VORNE
     */
    'maxX': number;
    /**
     * 
     * @type {number}
     * @memberof VORNE
     */
    'maxY': number;
    /**
     * 
     * @type {number}
     * @memberof VORNE
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof VORNE
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof VORNE
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VORNE
     */
    'components': Array<string>;
}
/**
 * 
 * @export
 * @interface VerboseDateOutput
 */
export interface VerboseDateOutput {
    /**
     * 
     * @type {string}
     * @memberof VerboseDateOutput
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof VerboseDateOutput
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof VerboseDateOutput
     */
    'noteEN': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerboseDateOutput
     */
    'businessDays': Array<string>;
}

/**
 * AssemblypartsApi - axios parameter creator
 * @export
 */
export const AssemblypartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerCreate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('assemblypartControllerCreate', 'body', body)
            const localVarPath = `/api/assemblyparts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assemblypartControllerDelete', 'id', id)
            const localVarPath = `/api/assemblyparts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assemblypartControllerFind', 'id', id)
            const localVarPath = `/api/assemblyparts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/assemblyparts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerUpdate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('assemblypartControllerUpdate', 'body', body)
            const localVarPath = `/api/assemblyparts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssemblypartsApi - functional programming interface
 * @export
 */
export const AssemblypartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssemblypartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assemblypartControllerCreate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assemblypartControllerCreate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assemblypartControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assemblypartControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assemblypartControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assemblypartControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assemblypartControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assemblypartControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assemblypartControllerUpdate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assemblypartControllerUpdate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssemblypartsApi - factory interface
 * @export
 */
export const AssemblypartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssemblypartsApiFp(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerCreate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.assemblypartControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.assemblypartControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerFind(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.assemblypartControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.assemblypartControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assemblypartControllerUpdate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.assemblypartControllerUpdate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssemblypartsApi - object-oriented interface
 * @export
 * @class AssemblypartsApi
 * @extends {BaseAPI}
 */
export class AssemblypartsApi extends BaseAPI {
    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssemblypartsApi
     */
    public assemblypartControllerCreate(body: object, options?: AxiosRequestConfig) {
        return AssemblypartsApiFp(this.configuration).assemblypartControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssemblypartsApi
     */
    public assemblypartControllerDelete(id: string, options?: AxiosRequestConfig) {
        return AssemblypartsApiFp(this.configuration).assemblypartControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssemblypartsApi
     */
    public assemblypartControllerFind(id: string, options?: AxiosRequestConfig) {
        return AssemblypartsApiFp(this.configuration).assemblypartControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssemblypartsApi
     */
    public assemblypartControllerFindAll(options?: AxiosRequestConfig) {
        return AssemblypartsApiFp(this.configuration).assemblypartControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssemblypartsApi
     */
    public assemblypartControllerUpdate(body: object, options?: AxiosRequestConfig) {
        return AssemblypartsApiFp(this.configuration).assemblypartControllerUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin: async (loginPayload: LoginPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginPayload' is not null or undefined
            assertParamExists('authControllerAdminLogin', 'loginPayload', loginPayload)
            const localVarPath = `/api/auth/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPassswordPayload} forgotPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword: async (forgotPassswordPayload: ForgotPassswordPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPassswordPayload' is not null or undefined
            assertParamExists('authControllerForgotPassword', 'forgotPassswordPayload', forgotPassswordPayload)
            const localVarPath = `/api/auth/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPassswordPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetLoggedInUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginPayload: LoginPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginPayload' is not null or undefined
            assertParamExists('authControllerLogin', 'loginPayload', loginPayload)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterPayload} registerPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegister: async (registerPayload: RegisterPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerPayload' is not null or undefined
            assertParamExists('authControllerRegister', 'registerPayload', registerPayload)
            const localVarPath = `/api/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPassswordPayload} resetPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPasswordWithToken: async (resetPassswordPayload: ResetPassswordPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPassswordPayload' is not null or undefined
            assertParamExists('authControllerResetPasswordWithToken', 'resetPassswordPayload', resetPassswordPayload)
            const localVarPath = `/api/auth/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPassswordPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAdminLogin(loginPayload: LoginPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAdminLogin(loginPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPassswordPayload} forgotPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerForgotPassword(forgotPassswordPayload: ForgotPassswordPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerForgotPassword(forgotPassswordPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetLoggedInUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetLoggedInUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginPayload: LoginPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterPayload} registerPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRegister(registerPayload: RegisterPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRegister(registerPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPassswordPayload} resetPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResetPasswordWithToken(resetPassswordPayload: ResetPassswordPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResetPasswordWithToken(resetPassswordPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin(loginPayload: LoginPayload, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authControllerAdminLogin(loginPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPassswordPayload} forgotPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword(forgotPassswordPayload: ForgotPassswordPayload, options?: any): AxiosPromise<boolean> {
            return localVarFp.authControllerForgotPassword(forgotPassswordPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetLoggedInUser(options?: any): AxiosPromise<User> {
            return localVarFp.authControllerGetLoggedInUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginPayload} loginPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginPayload: LoginPayload, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authControllerLogin(loginPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterPayload} registerPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegister(registerPayload: RegisterPayload, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authControllerRegister(registerPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPassswordPayload} resetPassswordPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPasswordWithToken(resetPassswordPayload: ResetPassswordPayload, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authControllerResetPasswordWithToken(resetPassswordPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {LoginPayload} loginPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAdminLogin(loginPayload: LoginPayload, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAdminLogin(loginPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPassswordPayload} forgotPassswordPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerForgotPassword(forgotPassswordPayload: ForgotPassswordPayload, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerForgotPassword(forgotPassswordPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerGetLoggedInUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerGetLoggedInUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginPayload} loginPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(loginPayload: LoginPayload, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(loginPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterPayload} registerPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRegister(registerPayload: RegisterPayload, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRegister(registerPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPassswordPayload} resetPassswordPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResetPasswordWithToken(resetPassswordPayload: ResetPassswordPayload, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerResetPasswordWithToken(resetPassswordPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * B7Api - axios parameter creator
 * @export
 */
export const B7ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b7ControllerTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/b7/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * B7Api - functional programming interface
 * @export
 */
export const B7ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = B7ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async b7ControllerTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.b7ControllerTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * B7Api - factory interface
 * @export
 */
export const B7ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = B7ApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        b7ControllerTest(options?: any): AxiosPromise<void> {
            return localVarFp.b7ControllerTest(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * B7Api - object-oriented interface
 * @export
 * @class B7Api
 * @extends {BaseAPI}
 */
export class B7Api extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B7Api
     */
    public b7ControllerTest(options?: AxiosRequestConfig) {
        return B7ApiFp(this.configuration).b7ControllerTest(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CabinetsApi - axios parameter creator
 * @export
 */
export const CabinetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerCreate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('cabinetControllerCreate', 'body', body)
            const localVarPath = `/api/cabinets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cabinetControllerDelete', 'id', id)
            const localVarPath = `/api/cabinets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cabinetControllerFind', 'id', id)
            const localVarPath = `/api/cabinets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cabinets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerMigrate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cabinets/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerUpdate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('cabinetControllerUpdate', 'body', body)
            const localVarPath = `/api/cabinets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CabinetsApi - functional programming interface
 * @export
 */
export const CabinetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CabinetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cabinetControllerCreate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cabinetControllerCreate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cabinetControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cabinetControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cabinetControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cabinetControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cabinetControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cabinetControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cabinetControllerMigrate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cabinetControllerMigrate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cabinetControllerUpdate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cabinetControllerUpdate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CabinetsApi - factory interface
 * @export
 */
export const CabinetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CabinetsApiFp(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerCreate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.cabinetControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cabinetControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerFind(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cabinetControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.cabinetControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerMigrate(options?: any): AxiosPromise<void> {
            return localVarFp.cabinetControllerMigrate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cabinetControllerUpdate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.cabinetControllerUpdate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CabinetsApi - object-oriented interface
 * @export
 * @class CabinetsApi
 * @extends {BaseAPI}
 */
export class CabinetsApi extends BaseAPI {
    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApi
     */
    public cabinetControllerCreate(body: object, options?: AxiosRequestConfig) {
        return CabinetsApiFp(this.configuration).cabinetControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApi
     */
    public cabinetControllerDelete(id: string, options?: AxiosRequestConfig) {
        return CabinetsApiFp(this.configuration).cabinetControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApi
     */
    public cabinetControllerFind(id: string, options?: AxiosRequestConfig) {
        return CabinetsApiFp(this.configuration).cabinetControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApi
     */
    public cabinetControllerFindAll(options?: AxiosRequestConfig) {
        return CabinetsApiFp(this.configuration).cabinetControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApi
     */
    public cabinetControllerMigrate(options?: AxiosRequestConfig) {
        return CabinetsApiFp(this.configuration).cabinetControllerMigrate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CabinetsApi
     */
    public cabinetControllerUpdate(body: object, options?: AxiosRequestConfig) {
        return CabinetsApiFp(this.configuration).cabinetControllerUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComponentApi - axios parameter creator
 * @export
 */
export const ComponentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/components/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerCreate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('componentControllerCreate', 'body', body)
            const localVarPath = `/api/components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('componentControllerDelete', 'id', id)
            const localVarPath = `/api/components/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('componentControllerFind', 'id', id)
            const localVarPath = `/api/components/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerFindAll: async (offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('componentControllerFindAll', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('componentControllerFindAll', 'limit', limit)
            const localVarPath = `/api/components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MigrationPayload} migrationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerMigrate: async (migrationPayload: MigrationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'migrationPayload' is not null or undefined
            assertParamExists('componentControllerMigrate', 'migrationPayload', migrationPayload)
            const localVarPath = `/api/components/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerSearch: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('componentControllerSearch', 'searchPayload', searchPayload)
            const localVarPath = `/api/components/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerSearchAggregate: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('componentControllerSearchAggregate', 'searchPayload', searchPayload)
            const localVarPath = `/api/components/count/aggr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerUpdate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('componentControllerUpdate', 'body', body)
            const localVarPath = `/api/components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComponentApi - functional programming interface
 * @export
 */
export const ComponentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComponentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentControllerCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentControllerCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentControllerCreate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentControllerCreate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Component>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentControllerFindAll(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MigrationPayload} migrationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentControllerMigrate(migrationPayload: MigrationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentControllerMigrate(migrationPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Component>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentControllerSearch(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentControllerSearchAggregate(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentControllerUpdate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentControllerUpdate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComponentApi - factory interface
 * @export
 */
export const ComponentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComponentApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerCount(options?: any): AxiosPromise<number> {
            return localVarFp.componentControllerCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerCreate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.componentControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.componentControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerFind(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.componentControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerFindAll(offset: number, limit: number, options?: any): AxiosPromise<Array<Component>> {
            return localVarFp.componentControllerFindAll(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MigrationPayload} migrationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerMigrate(migrationPayload: MigrationPayload, options?: any): AxiosPromise<void> {
            return localVarFp.componentControllerMigrate(migrationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerSearch(searchPayload: SearchPayload, options?: any): AxiosPromise<Array<Component>> {
            return localVarFp.componentControllerSearch(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerSearchAggregate(searchPayload: SearchPayload, options?: any): AxiosPromise<number> {
            return localVarFp.componentControllerSearchAggregate(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentControllerUpdate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.componentControllerUpdate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComponentApi - object-oriented interface
 * @export
 * @class ComponentApi
 * @extends {BaseAPI}
 */
export class ComponentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public componentControllerCount(options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).componentControllerCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public componentControllerCreate(body: object, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).componentControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public componentControllerDelete(id: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).componentControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public componentControllerFind(id: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).componentControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public componentControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).componentControllerFindAll(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MigrationPayload} migrationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public componentControllerMigrate(migrationPayload: MigrationPayload, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).componentControllerMigrate(migrationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public componentControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).componentControllerSearch(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public componentControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).componentControllerSearchAggregate(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public componentControllerUpdate(body: object, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).componentControllerUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountsApi - axios parameter creator
 * @export
 */
export const DiscountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerActiveDiscount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/discount/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/discount/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDiscountPayload} createDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerCreate: async (createDiscountPayload: CreateDiscountPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDiscountPayload' is not null or undefined
            assertParamExists('discountControllerCreate', 'createDiscountPayload', createDiscountPayload)
            const localVarPath = `/api/discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDiscountPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountControllerDelete', 'id', id)
            const localVarPath = `/api/discount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discountControllerFind', 'id', id)
            const localVarPath = `/api/discount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerFindAll: async (offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('discountControllerFindAll', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('discountControllerFindAll', 'limit', limit)
            const localVarPath = `/api/discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerSearch: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('discountControllerSearch', 'searchPayload', searchPayload)
            const localVarPath = `/api/discount/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerSearchAggregate: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('discountControllerSearchAggregate', 'searchPayload', searchPayload)
            const localVarPath = `/api/discount/count/aggr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDiscountPayload} updateDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerUpdate: async (updateDiscountPayload: UpdateDiscountPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDiscountPayload' is not null or undefined
            assertParamExists('discountControllerUpdate', 'updateDiscountPayload', updateDiscountPayload)
            const localVarPath = `/api/discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDiscountPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountsApi - functional programming interface
 * @export
 */
export const DiscountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerActiveDiscount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerActiveDiscount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDiscountPayload} createDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerCreate(createDiscountPayload: CreateDiscountPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerCreate(createDiscountPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Discount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerFindAll(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Discount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerSearch(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerSearchAggregate(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDiscountPayload} updateDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountControllerUpdate(updateDiscountPayload: UpdateDiscountPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountControllerUpdate(updateDiscountPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountsApi - factory interface
 * @export
 */
export const DiscountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerActiveDiscount(options?: any): AxiosPromise<Discount> {
            return localVarFp.discountControllerActiveDiscount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerCount(options?: any): AxiosPromise<number> {
            return localVarFp.discountControllerCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDiscountPayload} createDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerCreate(createDiscountPayload: CreateDiscountPayload, options?: any): AxiosPromise<Discount> {
            return localVarFp.discountControllerCreate(createDiscountPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.discountControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerFind(id: string, options?: any): AxiosPromise<Discount> {
            return localVarFp.discountControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerFindAll(offset: number, limit: number, options?: any): AxiosPromise<Array<Discount>> {
            return localVarFp.discountControllerFindAll(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerSearch(searchPayload: SearchPayload, options?: any): AxiosPromise<Array<Discount>> {
            return localVarFp.discountControllerSearch(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerSearchAggregate(searchPayload: SearchPayload, options?: any): AxiosPromise<number> {
            return localVarFp.discountControllerSearchAggregate(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDiscountPayload} updateDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountControllerUpdate(updateDiscountPayload: UpdateDiscountPayload, options?: any): AxiosPromise<Discount> {
            return localVarFp.discountControllerUpdate(updateDiscountPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountsApi - object-oriented interface
 * @export
 * @class DiscountsApi
 * @extends {BaseAPI}
 */
export class DiscountsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountControllerActiveDiscount(options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountControllerActiveDiscount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountControllerCount(options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountControllerCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDiscountPayload} createDiscountPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountControllerCreate(createDiscountPayload: CreateDiscountPayload, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountControllerCreate(createDiscountPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountControllerDelete(id: string, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountControllerFind(id: string, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountControllerFindAll(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountControllerSearch(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountControllerSearchAggregate(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDiscountPayload} updateDiscountPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountControllerUpdate(updateDiscountPayload: UpdateDiscountPayload, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountControllerUpdate(updateDiscountPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFilePayload} createFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerCreate: async (createFilePayload: CreateFilePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFilePayload' is not null or undefined
            assertParamExists('fileControllerCreate', 'createFilePayload', createFilePayload)
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFilePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fileControllerDelete', 'id', id)
            const localVarPath = `/api/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fileControllerFind', 'id', id)
            const localVarPath = `/api/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerMigrate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerUpdate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('fileControllerUpdate', 'body', body)
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFilePayload} createFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerCreate(createFilePayload: CreateFilePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerCreate(createFilePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerMigrate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerMigrate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerUpdate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerUpdate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFilePayload} createFilePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerCreate(createFilePayload: CreateFilePayload, options?: any): AxiosPromise<void> {
            return localVarFp.fileControllerCreate(createFilePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.fileControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerFind(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.fileControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.fileControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerMigrate(options?: any): AxiosPromise<void> {
            return localVarFp.fileControllerMigrate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerUpdate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.fileControllerUpdate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {CreateFilePayload} createFilePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public fileControllerCreate(createFilePayload: CreateFilePayload, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).fileControllerCreate(createFilePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public fileControllerDelete(id: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).fileControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public fileControllerFind(id: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).fileControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public fileControllerFindAll(options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).fileControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public fileControllerMigrate(options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).fileControllerMigrate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public fileControllerUpdate(body: object, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).fileControllerUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerHealth(options?: any): AxiosPromise<void> {
            return localVarFp.appControllerHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public appControllerHealth(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).appControllerHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HubspotApi - axios parameter creator
 * @export
 */
export const HubspotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotControllerCompanyTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hubspot/company/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotControllerExportOrders: async (offset: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('hubspotControllerExportOrders', 'offset', offset)
            const localVarPath = `/api/hubspot/orders/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotControllerExportUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hubspot/users/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotControllerImportUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hubspot/users/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HubspotApi - functional programming interface
 * @export
 */
export const HubspotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HubspotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubspotControllerCompanyTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubspotControllerCompanyTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubspotControllerExportOrders(offset: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubspotControllerExportOrders(offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubspotControllerExportUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubspotControllerExportUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubspotControllerImportUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubspotControllerImportUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HubspotApi - factory interface
 * @export
 */
export const HubspotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HubspotApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotControllerCompanyTest(options?: any): AxiosPromise<void> {
            return localVarFp.hubspotControllerCompanyTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotControllerExportOrders(offset: string, options?: any): AxiosPromise<void> {
            return localVarFp.hubspotControllerExportOrders(offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotControllerExportUsers(options?: any): AxiosPromise<void> {
            return localVarFp.hubspotControllerExportUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotControllerImportUsers(options?: any): AxiosPromise<void> {
            return localVarFp.hubspotControllerImportUsers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HubspotApi - object-oriented interface
 * @export
 * @class HubspotApi
 * @extends {BaseAPI}
 */
export class HubspotApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HubspotApi
     */
    public hubspotControllerCompanyTest(options?: AxiosRequestConfig) {
        return HubspotApiFp(this.configuration).hubspotControllerCompanyTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HubspotApi
     */
    public hubspotControllerExportOrders(offset: string, options?: AxiosRequestConfig) {
        return HubspotApiFp(this.configuration).hubspotControllerExportOrders(offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HubspotApi
     */
    public hubspotControllerExportUsers(options?: AxiosRequestConfig) {
        return HubspotApiFp(this.configuration).hubspotControllerExportUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HubspotApi
     */
    public hubspotControllerImportUsers(options?: AxiosRequestConfig) {
        return HubspotApiFp(this.configuration).hubspotControllerImportUsers(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LayoutApi - axios parameter creator
 * @export
 */
export const LayoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLayoutPayload} createLayoutPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerCreate: async (createLayoutPayload: CreateLayoutPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLayoutPayload' is not null or undefined
            assertParamExists('layoutControllerCreate', 'createLayoutPayload', createLayoutPayload)
            const localVarPath = `/api/layout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLayoutPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('layoutControllerDelete', 'id', id)
            const localVarPath = `/api/layout/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('layoutControllerFind', 'id', id)
            const localVarPath = `/api/layout/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/layout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerFindMyLayouts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/layout/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerUpdate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('layoutControllerUpdate', 'body', body)
            const localVarPath = `/api/layout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LayoutApi - functional programming interface
 * @export
 */
export const LayoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LayoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLayoutPayload} createLayoutPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutControllerCreate(createLayoutPayload: CreateLayoutPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutControllerCreate(createLayoutPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutControllerFindMyLayouts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Layout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutControllerFindMyLayouts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutControllerUpdate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutControllerUpdate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LayoutApi - factory interface
 * @export
 */
export const LayoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LayoutApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLayoutPayload} createLayoutPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerCreate(createLayoutPayload: CreateLayoutPayload, options?: any): AxiosPromise<void> {
            return localVarFp.layoutControllerCreate(createLayoutPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.layoutControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerFind(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.layoutControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.layoutControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerFindMyLayouts(options?: any): AxiosPromise<Array<Layout>> {
            return localVarFp.layoutControllerFindMyLayouts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutControllerUpdate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.layoutControllerUpdate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayoutApi - object-oriented interface
 * @export
 * @class LayoutApi
 * @extends {BaseAPI}
 */
export class LayoutApi extends BaseAPI {
    /**
     * 
     * @param {CreateLayoutPayload} createLayoutPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutControllerCreate(createLayoutPayload: CreateLayoutPayload, options?: AxiosRequestConfig) {
        return LayoutApiFp(this.configuration).layoutControllerCreate(createLayoutPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutControllerDelete(id: string, options?: AxiosRequestConfig) {
        return LayoutApiFp(this.configuration).layoutControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutControllerFind(id: string, options?: AxiosRequestConfig) {
        return LayoutApiFp(this.configuration).layoutControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutControllerFindAll(options?: AxiosRequestConfig) {
        return LayoutApiFp(this.configuration).layoutControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutControllerFindMyLayouts(options?: AxiosRequestConfig) {
        return LayoutApiFp(this.configuration).layoutControllerFindMyLayouts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutControllerUpdate(body: object, options?: AxiosRequestConfig) {
        return LayoutApiFp(this.configuration).layoutControllerUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logControllerDelete', 'id', id)
            const localVarPath = `/api/logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logControllerFind', 'id', id)
            const localVarPath = `/api/logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFindAll: async (offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('logControllerFindAll', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('logControllerFindAll', 'limit', limit)
            const localVarPath = `/api/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerFindAll(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.logControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFind(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.logControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFindAll(offset: number, limit: number, options?: any): AxiosPromise<void> {
            return localVarFp.logControllerFindAll(offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public logControllerDelete(id: string, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).logControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public logControllerFind(id: string, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).logControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public logControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).logControllerFindAll(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModuldefinitionsApi - axios parameter creator
 * @export
 */
export const ModuldefinitionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerCreate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('moduldefinitionControllerCreate', 'body', body)
            const localVarPath = `/api/moduldefinitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('moduldefinitionControllerDelete', 'id', id)
            const localVarPath = `/api/moduldefinitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('moduldefinitionControllerFind', 'id', id)
            const localVarPath = `/api/moduldefinitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moduldefinitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerUpdate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('moduldefinitionControllerUpdate', 'body', body)
            const localVarPath = `/api/moduldefinitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuldefinitionsApi - functional programming interface
 * @export
 */
export const ModuldefinitionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuldefinitionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moduldefinitionControllerCreate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moduldefinitionControllerCreate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moduldefinitionControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moduldefinitionControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moduldefinitionControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moduldefinitionControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moduldefinitionControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moduldefinitionControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moduldefinitionControllerUpdate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moduldefinitionControllerUpdate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModuldefinitionsApi - factory interface
 * @export
 */
export const ModuldefinitionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuldefinitionsApiFp(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerCreate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.moduldefinitionControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.moduldefinitionControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerFind(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.moduldefinitionControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.moduldefinitionControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moduldefinitionControllerUpdate(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.moduldefinitionControllerUpdate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuldefinitionsApi - object-oriented interface
 * @export
 * @class ModuldefinitionsApi
 * @extends {BaseAPI}
 */
export class ModuldefinitionsApi extends BaseAPI {
    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuldefinitionsApi
     */
    public moduldefinitionControllerCreate(body: object, options?: AxiosRequestConfig) {
        return ModuldefinitionsApiFp(this.configuration).moduldefinitionControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuldefinitionsApi
     */
    public moduldefinitionControllerDelete(id: string, options?: AxiosRequestConfig) {
        return ModuldefinitionsApiFp(this.configuration).moduldefinitionControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuldefinitionsApi
     */
    public moduldefinitionControllerFind(id: string, options?: AxiosRequestConfig) {
        return ModuldefinitionsApiFp(this.configuration).moduldefinitionControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuldefinitionsApi
     */
    public moduldefinitionControllerFindAll(options?: AxiosRequestConfig) {
        return ModuldefinitionsApiFp(this.configuration).moduldefinitionControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuldefinitionsApi
     */
    public moduldefinitionControllerUpdate(body: object, options?: AxiosRequestConfig) {
        return ModuldefinitionsApiFp(this.configuration).moduldefinitionControllerUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateAdditionalOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/aggr/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateAllLastWeekCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/last/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateAllThisWeekCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/this/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateCabinTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/aggr/cabin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateCabinTypesOrdered: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/aggr/cabin/ordered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateComponents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/component/aggr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateLastWeekPriceSum: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/last/price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateLastWeekPriceSumOffer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/last/priceoffer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateMaterialTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/aggr/material`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateMaterialTypesOrdered: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/aggr/material/ordered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOfferLastWeekCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/last/offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOfferThisWeekCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/this/offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOrderedLastWeekCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/last/ordered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOrderedThisWeekCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/this/ordered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/order/aggr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateStartedLastWeekCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/last/started`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateStartedThisWeekCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/this/started`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateThisWeekPriceSum: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/this/price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateThisWeekPriceSumOffer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/week/this/priceoffer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCountUserOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/me/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderPayload} createOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreate: async (createOrderPayload: CreateOrderPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderPayload' is not null or undefined
            assertParamExists('orderControllerCreate', 'createOrderPayload', createOrderPayload)
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateComponentOffer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerCreateComponentOffer', 'id', id)
            const localVarPath = `/api/orders/components/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateHubspotCsv: async (from: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('orderControllerCreateHubspotCsv', 'from', from)
            const localVarPath = `/api/orders/hubspot/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderPayload} createOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateMyOrder: async (createOrderPayload: CreateOrderPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderPayload' is not null or undefined
            assertParamExists('orderControllerCreateMyOrder', 'createOrderPayload', createOrderPayload)
            const localVarPath = `/api/orders/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOffer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerCreateOffer', 'id', id)
            const localVarPath = `/api/orders/offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderPayload} createOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrder: async (createOrderPayload: CreateOrderPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderPayload' is not null or undefined
            assertParamExists('orderControllerCreateOrder', 'createOrderPayload', createOrderPayload)
            const localVarPath = `/api/orders/guest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateOrderConfirmationPayload} createOrderConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrderConfirmation: async (id: string, createOrderConfirmationPayload: CreateOrderConfirmationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerCreateOrderConfirmation', 'id', id)
            // verify required parameter 'createOrderConfirmationPayload' is not null or undefined
            assertParamExists('orderControllerCreateOrderConfirmation', 'createOrderConfirmationPayload', createOrderConfirmationPayload)
            const localVarPath = `/api/orders/confirmation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderConfirmationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerDelete', 'id', id)
            const localVarPath = `/api/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerDetailPDF: async (id: string, force: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerDetailPDF', 'id', id)
            // verify required parameter 'force' is not null or undefined
            assertParamExists('orderControllerDetailPDF', 'force', force)
            const localVarPath = `/api/orders/{id}/detail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerFind', 'id', id)
            const localVarPath = `/api/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindAll: async (offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('orderControllerFindAll', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('orderControllerFindAll', 'limit', limit)
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindByShare: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerFindByShare', 'id', id)
            const localVarPath = `/api/orders/share/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {boolean} [all] 
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindMyOrders: async (offset: number, limit: number, all?: boolean, search?: string, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('orderControllerFindMyOrders', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('orderControllerFindMyOrders', 'limit', limit)
            const localVarPath = `/api/orders/myorders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGenerateAndSaveCode: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerGenerateAndSaveCode', 'id', id)
            const localVarPath = `/api/orders/{id}/code`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrdernumber: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/ordernumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MigrationPayload} migrationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerMigrate: async (migrationPayload: MigrationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'migrationPayload' is not null or undefined
            assertParamExists('orderControllerMigrate', 'migrationPayload', migrationPayload)
            const localVarPath = `/api/orders/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerMigratePrice: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/migrateprice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerMigrateScreens: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/migratescreens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerMigrateWeekdays: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/migrateweekdays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSearch: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('orderControllerSearch', 'searchPayload', searchPayload)
            const localVarPath = `/api/orders/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSearchAggregate: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('orderControllerSearchAggregate', 'searchPayload', searchPayload)
            const localVarPath = `/api/orders/count/aggr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ShareBody} shareBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSharewith: async (id: string, shareBody: ShareBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerSharewith', 'id', id)
            // verify required parameter 'shareBody' is not null or undefined
            assertParamExists('orderControllerSharewith', 'shareBody', shareBody)
            const localVarPath = `/api/orders/{id}/sharewith`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderPayload} updateOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateGuestOrder: async (id: string, updateOrderPayload: UpdateOrderPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerUpdateGuestOrder', 'id', id)
            // verify required parameter 'updateOrderPayload' is not null or undefined
            assertParamExists('orderControllerUpdateGuestOrder', 'updateOrderPayload', updateOrderPayload)
            const localVarPath = `/api/orders/guest/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOrderPayload} updateOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateMyOrder: async (updateOrderPayload: UpdateOrderPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOrderPayload' is not null or undefined
            assertParamExists('orderControllerUpdateMyOrder', 'updateOrderPayload', updateOrderPayload)
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateProductionStatus: async (id: string, status: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerUpdateProductionStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('orderControllerUpdateProductionStatus', 'status', status)
            const localVarPath = `/api/orders/{id}/productionstatus/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateStatus: async (id: string, status: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerUpdateStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('orderControllerUpdateStatus', 'status', status)
            const localVarPath = `/api/orders/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateAdditionalOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateOptionsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateAdditionalOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateAllLastWeekCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateAllLastWeekCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateAllThisWeekCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateAllThisWeekCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateCabinTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateCabinResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateCabinTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateCabinTypesOrdered(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateCabinResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateCabinTypesOrdered(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateComponents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateComponents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateLastWeekPriceSum(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateLastWeekPriceSum(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateLastWeekPriceSumOffer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateLastWeekPriceSumOffer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateMaterialTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateMaterialResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateMaterialTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateMaterialTypesOrdered(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateMaterialResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateMaterialTypesOrdered(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateOfferLastWeekCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateOfferLastWeekCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateOfferThisWeekCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateOfferThisWeekCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateOrderedLastWeekCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateOrderedLastWeekCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateOrderedThisWeekCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateOrderedThisWeekCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateStartedLastWeekCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateStartedLastWeekCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateStartedThisWeekCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateStartedThisWeekCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateThisWeekPriceSum(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateThisWeekPriceSum(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerAggregateThisWeekPriceSumOffer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerAggregateThisWeekPriceSumOffer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCountUserOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCountUserOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrderPayload} createOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreate(createOrderPayload: CreateOrderPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreate(createOrderPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreateComponentOffer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreateComponentOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreateHubspotCsv(from: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreateHubspotCsv(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrderPayload} createOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreateMyOrder(createOrderPayload: CreateOrderPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreateMyOrder(createOrderPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreateOffer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreateOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrderPayload} createOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreateOrder(createOrderPayload: CreateOrderPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreateOrder(createOrderPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateOrderConfirmationPayload} createOrderConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreateOrderConfirmation(id: string, createOrderConfirmationPayload: CreateOrderConfirmationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreateOrderConfirmation(id, createOrderConfirmationPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerDetailPDF(id: string, force: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerDetailPDF(id, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFindAll(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFindByShare(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFindByShare(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {boolean} [all] 
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFindMyOrders(offset: number, limit: number, all?: boolean, search?: string, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyOrderResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFindMyOrders(offset, limit, all, search, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGenerateAndSaveCode(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGenerateAndSaveCode(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGetOrdernumber(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGetOrdernumber(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MigrationPayload} migrationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerMigrate(migrationPayload: MigrationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerMigrate(migrationPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerMigratePrice(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerMigratePrice(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerMigrateScreens(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerMigrateScreens(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerMigrateWeekdays(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerMigrateWeekdays(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerSearch(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerSearchAggregate(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ShareBody} shareBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerSharewith(id: string, shareBody: ShareBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerSharewith(id, shareBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderPayload} updateOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateGuestOrder(id: string, updateOrderPayload: UpdateOrderPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateGuestOrder(id, updateOrderPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateOrderPayload} updateOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateMyOrder(updateOrderPayload: UpdateOrderPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateMyOrder(updateOrderPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateProductionStatus(id: string, status: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateProductionStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateStatus(id: string, status: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateAdditionalOptions(options?: any): AxiosPromise<AggregateOptionsResult> {
            return localVarFp.orderControllerAggregateAdditionalOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateAllLastWeekCount(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateAllLastWeekCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateAllThisWeekCount(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateAllThisWeekCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateCabinTypes(options?: any): AxiosPromise<Array<AggregateCabinResult>> {
            return localVarFp.orderControllerAggregateCabinTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateCabinTypesOrdered(options?: any): AxiosPromise<Array<AggregateCabinResult>> {
            return localVarFp.orderControllerAggregateCabinTypesOrdered(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateComponents(options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerAggregateComponents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateLastWeekPriceSum(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateLastWeekPriceSum(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateLastWeekPriceSumOffer(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateLastWeekPriceSumOffer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateMaterialTypes(options?: any): AxiosPromise<Array<AggregateMaterialResult>> {
            return localVarFp.orderControllerAggregateMaterialTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateMaterialTypesOrdered(options?: any): AxiosPromise<Array<AggregateMaterialResult>> {
            return localVarFp.orderControllerAggregateMaterialTypesOrdered(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOfferLastWeekCount(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateOfferLastWeekCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOfferThisWeekCount(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateOfferThisWeekCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOrderedLastWeekCount(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateOrderedLastWeekCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOrderedThisWeekCount(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateOrderedThisWeekCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateOrders(options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerAggregateOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateStartedLastWeekCount(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateStartedLastWeekCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateStartedThisWeekCount(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateStartedThisWeekCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateThisWeekPriceSum(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateThisWeekPriceSum(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerAggregateThisWeekPriceSumOffer(options?: any): AxiosPromise<AggregateResult> {
            return localVarFp.orderControllerAggregateThisWeekPriceSumOffer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCount(options?: any): AxiosPromise<number> {
            return localVarFp.orderControllerCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCountUserOrders(options?: any): AxiosPromise<number> {
            return localVarFp.orderControllerCountUserOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrderPayload} createOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreate(createOrderPayload: CreateOrderPayload, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerCreate(createOrderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateComponentOffer(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerCreateComponentOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateHubspotCsv(from: number, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerCreateHubspotCsv(from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrderPayload} createOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateMyOrder(createOrderPayload: CreateOrderPayload, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerCreateMyOrder(createOrderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOffer(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerCreateOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrderPayload} createOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrder(createOrderPayload: CreateOrderPayload, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerCreateOrder(createOrderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateOrderConfirmationPayload} createOrderConfirmationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrderConfirmation(id: string, createOrderConfirmationPayload: CreateOrderConfirmationPayload, options?: any): AxiosPromise<boolean> {
            return localVarFp.orderControllerCreateOrderConfirmation(id, createOrderConfirmationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.orderControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerDetailPDF(id: string, force: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerDetailPDF(id, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFind(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindAll(offset: number, limit: number, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.orderControllerFindAll(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindByShare(id: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerFindByShare(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {boolean} [all] 
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindMyOrders(offset: number, limit: number, all?: boolean, search?: string, status?: string, options?: any): AxiosPromise<MyOrderResult> {
            return localVarFp.orderControllerFindMyOrders(offset, limit, all, search, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGenerateAndSaveCode(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.orderControllerGenerateAndSaveCode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrdernumber(options?: any): AxiosPromise<number> {
            return localVarFp.orderControllerGetOrdernumber(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MigrationPayload} migrationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerMigrate(migrationPayload: MigrationPayload, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerMigrate(migrationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerMigratePrice(options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerMigratePrice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerMigrateScreens(options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerMigrateScreens(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerMigrateWeekdays(options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerMigrateWeekdays(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSearch(searchPayload: SearchPayload, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.orderControllerSearch(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSearchAggregate(searchPayload: SearchPayload, options?: any): AxiosPromise<number> {
            return localVarFp.orderControllerSearchAggregate(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ShareBody} shareBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSharewith(id: string, shareBody: ShareBody, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerSharewith(id, shareBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderPayload} updateOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateGuestOrder(id: string, updateOrderPayload: UpdateOrderPayload, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerUpdateGuestOrder(id, updateOrderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOrderPayload} updateOrderPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateMyOrder(updateOrderPayload: UpdateOrderPayload, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerUpdateMyOrder(updateOrderPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateProductionStatus(id: string, status: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerUpdateProductionStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateStatus(id: string, status: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerUpdateStatus(id, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateAdditionalOptions(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateAdditionalOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateAllLastWeekCount(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateAllLastWeekCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateAllThisWeekCount(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateAllThisWeekCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateCabinTypes(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateCabinTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateCabinTypesOrdered(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateCabinTypesOrdered(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateComponents(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateComponents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateLastWeekPriceSum(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateLastWeekPriceSum(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateLastWeekPriceSumOffer(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateLastWeekPriceSumOffer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateMaterialTypes(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateMaterialTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateMaterialTypesOrdered(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateMaterialTypesOrdered(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateOfferLastWeekCount(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateOfferLastWeekCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateOfferThisWeekCount(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateOfferThisWeekCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateOrderedLastWeekCount(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateOrderedLastWeekCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateOrderedThisWeekCount(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateOrderedThisWeekCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateOrders(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateStartedLastWeekCount(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateStartedLastWeekCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateStartedThisWeekCount(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateStartedThisWeekCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateThisWeekPriceSum(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateThisWeekPriceSum(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerAggregateThisWeekPriceSumOffer(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerAggregateThisWeekPriceSumOffer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCount(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCountUserOrders(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCountUserOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrderPayload} createOrderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreate(createOrderPayload: CreateOrderPayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreate(createOrderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreateComponentOffer(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreateComponentOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} from 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreateHubspotCsv(from: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreateHubspotCsv(from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrderPayload} createOrderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreateMyOrder(createOrderPayload: CreateOrderPayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreateMyOrder(createOrderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreateOffer(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreateOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrderPayload} createOrderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreateOrder(createOrderPayload: CreateOrderPayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreateOrder(createOrderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateOrderConfirmationPayload} createOrderConfirmationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreateOrderConfirmation(id: string, createOrderConfirmationPayload: CreateOrderConfirmationPayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreateOrderConfirmation(id, createOrderConfirmationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerDelete(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerDetailPDF(id: string, force: boolean, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerDetailPDF(id, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerFind(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerFindAll(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerFindByShare(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerFindByShare(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {boolean} [all] 
     * @param {string} [search] 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerFindMyOrders(offset: number, limit: number, all?: boolean, search?: string, status?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerFindMyOrders(offset, limit, all, search, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerGenerateAndSaveCode(id: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerGenerateAndSaveCode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerGetOrdernumber(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerGetOrdernumber(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MigrationPayload} migrationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerMigrate(migrationPayload: MigrationPayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerMigrate(migrationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerMigratePrice(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerMigratePrice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerMigrateScreens(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerMigrateScreens(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerMigrateWeekdays(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerMigrateWeekdays(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerSearch(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerSearchAggregate(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ShareBody} shareBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerSharewith(id: string, shareBody: ShareBody, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerSharewith(id, shareBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateOrderPayload} updateOrderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateGuestOrder(id: string, updateOrderPayload: UpdateOrderPayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateGuestOrder(id, updateOrderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOrderPayload} updateOrderPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateMyOrder(updateOrderPayload: UpdateOrderPayload, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateMyOrder(updateOrderPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateProductionStatus(id: string, status: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateProductionStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateStatus(id: string, status: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerAcceptInvite: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationControllerAcceptInvite', 'id', id)
            const localVarPath = `/api/organizations/me/invites/{id}/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrganizationPayload} createOrganizationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreate: async (createOrganizationPayload: CreateOrganizationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationPayload' is not null or undefined
            assertParamExists('organizationControllerCreate', 'createOrganizationPayload', createOrganizationPayload)
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAddressPayload} updateAddressPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreateMyAddress: async (updateAddressPayload: UpdateAddressPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAddressPayload' is not null or undefined
            assertParamExists('organizationControllerCreateMyAddress', 'updateAddressPayload', updateAddressPayload)
            const localVarPath = `/api/organizations/me/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationControllerDelete', 'id', id)
            const localVarPath = `/api/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerExportToCSV: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationControllerFind', 'id', id)
            const localVarPath = `/api/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindAll: async (offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('organizationControllerFindAll', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationControllerFindAll', 'limit', limit)
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindOrganizations: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('organizationControllerFindOrganizations', 'name', name)
            const localVarPath = `/api/organizations/find/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'register' | 'initial' | 'invite'} [reason] 
         * @param {'pending' | 'rejected' | 'accepted'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetMyInvites: async (reason?: 'register' | 'initial' | 'invite', status?: 'pending' | 'rejected' | 'accepted', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/me/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetMyOrganization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {string} [suffix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerInit: async (limit?: number, suffix?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (suffix !== undefined) {
                localVarQueryParameter['suffix'] = suffix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerInvite: async (id: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationControllerInvite', 'id', id)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('organizationControllerInvite', 'email', email)
            const localVarPath = `/api/organizations/{id}/invite/{email}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerJoinOrganization: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationControllerJoinOrganization', 'id', id)
            const localVarPath = `/api/organizations/{id}/join`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerLeaveMyOrganization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/me/leave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerRejectInvite: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationControllerRejectInvite', 'id', id)
            const localVarPath = `/api/organizations/me/invites/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSearch: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('organizationControllerSearch', 'searchPayload', searchPayload)
            const localVarPath = `/api/organizations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSearchAggregate: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('organizationControllerSearchAggregate', 'searchPayload', searchPayload)
            const localVarPath = `/api/organizations/count/aggr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOrganizationPayload} updateOrganizationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdate: async (updateOrganizationPayload: UpdateOrganizationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOrganizationPayload' is not null or undefined
            assertParamExists('organizationControllerUpdate', 'updateOrganizationPayload', updateOrganizationPayload)
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} addressId 
         * @param {UpdateAddressPayload} updateAddressPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateAddress: async (addressId: string, updateAddressPayload: UpdateAddressPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('organizationControllerUpdateAddress', 'addressId', addressId)
            // verify required parameter 'updateAddressPayload' is not null or undefined
            assertParamExists('organizationControllerUpdateAddress', 'updateAddressPayload', updateAddressPayload)
            const localVarPath = `/api/organizations/address/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} addressId 
         * @param {UpdateAddressPayload} updateAddressPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateMyAddress: async (addressId: string, updateAddressPayload: UpdateAddressPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('organizationControllerUpdateMyAddress', 'addressId', addressId)
            // verify required parameter 'updateAddressPayload' is not null or undefined
            assertParamExists('organizationControllerUpdateMyAddress', 'updateAddressPayload', updateAddressPayload)
            const localVarPath = `/api/organizations/me/address/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerAcceptInvite(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerAcceptInvite(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrganizationPayload} createOrganizationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerCreate(createOrganizationPayload: CreateOrganizationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerCreate(createOrganizationPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAddressPayload} updateAddressPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerCreateMyAddress(updateAddressPayload: UpdateAddressPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerCreateMyAddress(updateAddressPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerExportToCSV(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerExportToCSV(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerFindAll(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerFindOrganizations(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerFindOrganizations(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'register' | 'initial' | 'invite'} [reason] 
         * @param {'pending' | 'rejected' | 'accepted'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerGetMyInvites(reason?: 'register' | 'initial' | 'invite', status?: 'pending' | 'rejected' | 'accepted', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationInvite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerGetMyInvites(reason, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerGetMyOrganization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerGetMyOrganization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {string} [suffix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerInit(limit?: number, suffix?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerInit(limit, suffix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerInvite(id: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerInvite(id, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerJoinOrganization(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerJoinOrganization(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerLeaveMyOrganization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerLeaveMyOrganization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerRejectInvite(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerRejectInvite(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerSearch(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerSearchAggregate(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateOrganizationPayload} updateOrganizationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerUpdate(updateOrganizationPayload: UpdateOrganizationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerUpdate(updateOrganizationPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} addressId 
         * @param {UpdateAddressPayload} updateAddressPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerUpdateAddress(addressId: string, updateAddressPayload: UpdateAddressPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerUpdateAddress(addressId, updateAddressPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} addressId 
         * @param {UpdateAddressPayload} updateAddressPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerUpdateMyAddress(addressId: string, updateAddressPayload: UpdateAddressPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerUpdateMyAddress(addressId, updateAddressPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerAcceptInvite(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationControllerAcceptInvite(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCount(options?: any): AxiosPromise<number> {
            return localVarFp.organizationControllerCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrganizationPayload} createOrganizationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreate(createOrganizationPayload: CreateOrganizationPayload, options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationControllerCreate(createOrganizationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAddressPayload} updateAddressPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreateMyAddress(updateAddressPayload: UpdateAddressPayload, options?: any): AxiosPromise<Address> {
            return localVarFp.organizationControllerCreateMyAddress(updateAddressPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerExportToCSV(options?: any): AxiosPromise<string> {
            return localVarFp.organizationControllerExportToCSV(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFind(id: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindAll(offset: number, limit: number, options?: any): AxiosPromise<Array<Organization>> {
            return localVarFp.organizationControllerFindAll(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindOrganizations(name: string, options?: any): AxiosPromise<Array<Organization>> {
            return localVarFp.organizationControllerFindOrganizations(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'register' | 'initial' | 'invite'} [reason] 
         * @param {'pending' | 'rejected' | 'accepted'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetMyInvites(reason?: 'register' | 'initial' | 'invite', status?: 'pending' | 'rejected' | 'accepted', options?: any): AxiosPromise<Array<OrganizationInvite>> {
            return localVarFp.organizationControllerGetMyInvites(reason, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetMyOrganization(options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationControllerGetMyOrganization(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {string} [suffix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerInit(limit?: number, suffix?: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationControllerInit(limit, suffix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerInvite(id: string, email: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationControllerInvite(id, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerJoinOrganization(id: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationControllerJoinOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerLeaveMyOrganization(options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationControllerLeaveMyOrganization(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerRejectInvite(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationControllerRejectInvite(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSearch(searchPayload: SearchPayload, options?: any): AxiosPromise<Array<Organization>> {
            return localVarFp.organizationControllerSearch(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSearchAggregate(searchPayload: SearchPayload, options?: any): AxiosPromise<number> {
            return localVarFp.organizationControllerSearchAggregate(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOrganizationPayload} updateOrganizationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdate(updateOrganizationPayload: UpdateOrganizationPayload, options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationControllerUpdate(updateOrganizationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} addressId 
         * @param {UpdateAddressPayload} updateAddressPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateAddress(addressId: string, updateAddressPayload: UpdateAddressPayload, options?: any): AxiosPromise<Address> {
            return localVarFp.organizationControllerUpdateAddress(addressId, updateAddressPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} addressId 
         * @param {UpdateAddressPayload} updateAddressPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateMyAddress(addressId: string, updateAddressPayload: UpdateAddressPayload, options?: any): AxiosPromise<Address> {
            return localVarFp.organizationControllerUpdateMyAddress(addressId, updateAddressPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerAcceptInvite(id: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerAcceptInvite(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerCount(options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrganizationPayload} createOrganizationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerCreate(createOrganizationPayload: CreateOrganizationPayload, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerCreate(createOrganizationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAddressPayload} updateAddressPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerCreateMyAddress(updateAddressPayload: UpdateAddressPayload, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerCreateMyAddress(updateAddressPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerDelete(id: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerExportToCSV(options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerExportToCSV(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerFind(id: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerFindAll(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerFindOrganizations(name: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerFindOrganizations(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'register' | 'initial' | 'invite'} [reason] 
     * @param {'pending' | 'rejected' | 'accepted'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerGetMyInvites(reason?: 'register' | 'initial' | 'invite', status?: 'pending' | 'rejected' | 'accepted', options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerGetMyInvites(reason, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerGetMyOrganization(options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerGetMyOrganization(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {string} [suffix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerInit(limit?: number, suffix?: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerInit(limit, suffix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerInvite(id: string, email: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerInvite(id, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerJoinOrganization(id: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerJoinOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerLeaveMyOrganization(options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerLeaveMyOrganization(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerRejectInvite(id: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerRejectInvite(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerSearch(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerSearchAggregate(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOrganizationPayload} updateOrganizationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerUpdate(updateOrganizationPayload: UpdateOrganizationPayload, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerUpdate(updateOrganizationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} addressId 
     * @param {UpdateAddressPayload} updateAddressPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerUpdateAddress(addressId: string, updateAddressPayload: UpdateAddressPayload, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerUpdateAddress(addressId, updateAddressPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} addressId 
     * @param {UpdateAddressPayload} updateAddressPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationControllerUpdateMyAddress(addressId: string, updateAddressPayload: UpdateAddressPayload, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationControllerUpdateMyAddress(addressId, updateAddressPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PriceConfigApi - axios parameter creator
 * @export
 */
export const PriceConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePriceconfigPayload} createPriceconfigPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerCreate: async (createPriceconfigPayload: CreatePriceconfigPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPriceconfigPayload' is not null or undefined
            assertParamExists('priceconfigControllerCreate', 'createPriceconfigPayload', createPriceconfigPayload)
            const localVarPath = `/api/priceconfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPriceconfigPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('priceconfigControllerDelete', 'id', id)
            const localVarPath = `/api/priceconfigs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('priceconfigControllerFind', 'id', id)
            const localVarPath = `/api/priceconfigs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/priceconfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerLatest: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('priceconfigControllerLatest', 'type', type)
            const localVarPath = `/api/priceconfigs/latest/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalculationOrder} calculationOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerPriceAdminCalc: async (calculationOrder: CalculationOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calculationOrder' is not null or undefined
            assertParamExists('priceconfigControllerPriceAdminCalc', 'calculationOrder', calculationOrder)
            const localVarPath = `/api/priceconfigs/admincalc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculationOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalculationOrder} calculationOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerPriceCalc: async (calculationOrder: CalculationOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calculationOrder' is not null or undefined
            assertParamExists('priceconfigControllerPriceCalc', 'calculationOrder', calculationOrder)
            const localVarPath = `/api/priceconfigs/calc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculationOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerPriceCalcNew: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('priceconfigControllerPriceCalcNew', 'id', id)
            const localVarPath = `/api/priceconfigs/calc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerUpdate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('priceconfigControllerUpdate', 'body', body)
            const localVarPath = `/api/priceconfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceConfigApi - functional programming interface
 * @export
 */
export const PriceConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePriceconfigPayload} createPriceconfigPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceconfigControllerCreate(createPriceconfigPayload: CreatePriceconfigPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Priceconfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceconfigControllerCreate(createPriceconfigPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceconfigControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceconfigControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceconfigControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Priceconfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceconfigControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceconfigControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Priceconfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceconfigControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceconfigControllerLatest(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceconfigControllerLatest(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CalculationOrder} calculationOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceconfigControllerPriceAdminCalc(calculationOrder: CalculationOrder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceconfigControllerPriceAdminCalc(calculationOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CalculationOrder} calculationOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceconfigControllerPriceCalc(calculationOrder: CalculationOrder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceconfigControllerPriceCalc(calculationOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceconfigControllerPriceCalcNew(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceconfigControllerPriceCalcNew(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceconfigControllerUpdate(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Priceconfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceconfigControllerUpdate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriceConfigApi - factory interface
 * @export
 */
export const PriceConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePriceconfigPayload} createPriceconfigPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerCreate(createPriceconfigPayload: CreatePriceconfigPayload, options?: any): AxiosPromise<Priceconfig> {
            return localVarFp.priceconfigControllerCreate(createPriceconfigPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.priceconfigControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerFind(id: string, options?: any): AxiosPromise<Priceconfig> {
            return localVarFp.priceconfigControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerFindAll(options?: any): AxiosPromise<Array<Priceconfig>> {
            return localVarFp.priceconfigControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerLatest(type: string, options?: any): AxiosPromise<void> {
            return localVarFp.priceconfigControllerLatest(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalculationOrder} calculationOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerPriceAdminCalc(calculationOrder: CalculationOrder, options?: any): AxiosPromise<void> {
            return localVarFp.priceconfigControllerPriceAdminCalc(calculationOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalculationOrder} calculationOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerPriceCalc(calculationOrder: CalculationOrder, options?: any): AxiosPromise<void> {
            return localVarFp.priceconfigControllerPriceCalc(calculationOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerPriceCalcNew(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.priceconfigControllerPriceCalcNew(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceconfigControllerUpdate(body: object, options?: any): AxiosPromise<Priceconfig> {
            return localVarFp.priceconfigControllerUpdate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceConfigApi - object-oriented interface
 * @export
 * @class PriceConfigApi
 * @extends {BaseAPI}
 */
export class PriceConfigApi extends BaseAPI {
    /**
     * 
     * @param {CreatePriceconfigPayload} createPriceconfigPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceconfigControllerCreate(createPriceconfigPayload: CreatePriceconfigPayload, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceconfigControllerCreate(createPriceconfigPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceconfigControllerDelete(id: string, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceconfigControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceconfigControllerFind(id: string, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceconfigControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceconfigControllerFindAll(options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceconfigControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceconfigControllerLatest(type: string, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceconfigControllerLatest(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalculationOrder} calculationOrder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceconfigControllerPriceAdminCalc(calculationOrder: CalculationOrder, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceconfigControllerPriceAdminCalc(calculationOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalculationOrder} calculationOrder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceconfigControllerPriceCalc(calculationOrder: CalculationOrder, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceconfigControllerPriceCalc(calculationOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceconfigControllerPriceCalcNew(id: string, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceconfigControllerPriceCalcNew(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceConfigApi
     */
    public priceconfigControllerUpdate(body: object, options?: AxiosRequestConfig) {
        return PriceConfigApiFp(this.configuration).priceconfigControllerUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TypenschildApi - axios parameter creator
 * @export
 */
export const TypenschildApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMLControllerGetTypenSchild: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('xMLControllerGetTypenSchild', 'id', id)
            const localVarPath = `/api/typenschild/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMLControllerReUploadOrderXML: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('xMLControllerReUploadOrderXML', 'id', id)
            const localVarPath = `/api/typenschild/order/xml/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypenschildApi - functional programming interface
 * @export
 */
export const TypenschildApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypenschildApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMLControllerGetTypenSchild(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMLControllerGetTypenSchild(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMLControllerReUploadOrderXML(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMLControllerReUploadOrderXML(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TypenschildApi - factory interface
 * @export
 */
export const TypenschildApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypenschildApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMLControllerGetTypenSchild(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.xMLControllerGetTypenSchild(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMLControllerReUploadOrderXML(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.xMLControllerReUploadOrderXML(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TypenschildApi - object-oriented interface
 * @export
 * @class TypenschildApi
 * @extends {BaseAPI}
 */
export class TypenschildApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypenschildApi
     */
    public xMLControllerGetTypenSchild(id: string, options?: AxiosRequestConfig) {
        return TypenschildApiFp(this.configuration).xMLControllerGetTypenSchild(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypenschildApi
     */
    public xMLControllerReUploadOrderXML(id: string, options?: AxiosRequestConfig) {
        return TypenschildApiFp(this.configuration).xMLControllerReUploadOrderXML(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAggregateMigratedUsersCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/aggregate/migratedusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserPayload} createUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreate: async (createUserPayload: CreateUserPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserPayload' is not null or undefined
            assertParamExists('userControllerCreate', 'createUserPayload', createUserPayload)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerDelete', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerFind', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAll: async (offset: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('userControllerFindAll', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('userControllerFindAll', 'limit', limit)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lock the user
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLock: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerLock', 'id', id)
            const localVarPath = `/api/users/{id}/lock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Internal Use only. Will migrate users from the Old system
         * @summary 
         * @param {MigrationPayload} migrationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerMigrate: async (migrationPayload: MigrationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'migrationPayload' is not null or undefined
            assertParamExists('userControllerMigrate', 'migrationPayload', migrationPayload)
            const localVarPath = `/api/users/migrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSearch: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('userControllerSearch', 'searchPayload', searchPayload)
            const localVarPath = `/api/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSearchAggregate: async (searchPayload: SearchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPayload' is not null or undefined
            assertParamExists('userControllerSearchAggregate', 'searchPayload', searchPayload)
            const localVarPath = `/api/users/count/aggr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lock the user
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnlock: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerUnlock', 'id', id)
            const localVarPath = `/api/users/{id}/unlock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserPayload} updateUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate: async (updateUserPayload: UpdateUserPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserPayload' is not null or undefined
            assertParamExists('userControllerUpdate', 'updateUserPayload', updateUserPayload)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerAggregateMigratedUsersCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateMigratedUsersCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerAggregateMigratedUsersCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserPayload} createUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreate(createUserPayload: CreateUserPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreate(createUserPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindAll(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lock the user
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerLock(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerLock(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Internal Use only. Will migrate users from the Old system
         * @summary 
         * @param {MigrationPayload} migrationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerMigrate(migrationPayload: MigrationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerMigrate(migrationPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSearch(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSearchAggregate(searchPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lock the user
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUnlock(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUnlock(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserPayload} updateUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdate(updateUserPayload: UpdateUserPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdate(updateUserPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAggregateMigratedUsersCount(options?: any): AxiosPromise<AggregateMigratedUsersCountResult> {
            return localVarFp.userControllerAggregateMigratedUsersCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCount(options?: any): AxiosPromise<number> {
            return localVarFp.userControllerCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserPayload} createUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreate(createUserPayload: CreateUserPayload, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerCreate(createUserPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.userControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFind(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offset 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAll(offset: number, limit: number, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.userControllerFindAll(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Lock the user
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLock(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerLock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Internal Use only. Will migrate users from the Old system
         * @summary 
         * @param {MigrationPayload} migrationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerMigrate(migrationPayload: MigrationPayload, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerMigrate(migrationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSearch(searchPayload: SearchPayload, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.userControllerSearch(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPayload} searchPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSearchAggregate(searchPayload: SearchPayload, options?: any): AxiosPromise<number> {
            return localVarFp.userControllerSearchAggregate(searchPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Lock the user
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnlock(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerUnlock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserPayload} updateUserPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate(updateUserPayload: UpdateUserPayload, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerUpdate(updateUserPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerAggregateMigratedUsersCount(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerAggregateMigratedUsersCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCount(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserPayload} createUserPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreate(createUserPayload: CreateUserPayload, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCreate(createUserPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerDelete(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerFind(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerFind(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} offset 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerFindAll(offset: number, limit: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerFindAll(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lock the user
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerLock(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerLock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Internal Use only. Will migrate users from the Old system
     * @summary 
     * @param {MigrationPayload} migrationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerMigrate(migrationPayload: MigrationPayload, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerMigrate(migrationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerSearch(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerSearch(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPayload} searchPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerSearchAggregate(searchPayload: SearchPayload, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerSearchAggregate(searchPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lock the user
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUnlock(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUnlock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserPayload} updateUserPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdate(updateUserPayload: UpdateUserPayload, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdate(updateUserPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilityApi - axios parameter creator
 * @export
 */
export const UtilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilityControllerGetDeliveryDate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/utility/deliverydate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilityControllerGetDeliveryDateVerbose: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/utility/deliverydate/verbose`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilityControllerGetHolidays: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/utility/holidays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityApi - functional programming interface
 * @export
 */
export const UtilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilityControllerGetDeliveryDate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerboseDateOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilityControllerGetDeliveryDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilityControllerGetDeliveryDateVerbose(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerboseDateOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilityControllerGetDeliveryDateVerbose(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilityControllerGetHolidays(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilityControllerGetHolidays(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilityApi - factory interface
 * @export
 */
export const UtilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilityApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilityControllerGetDeliveryDate(options?: any): AxiosPromise<VerboseDateOutput> {
            return localVarFp.utilityControllerGetDeliveryDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilityControllerGetDeliveryDateVerbose(options?: any): AxiosPromise<VerboseDateOutput> {
            return localVarFp.utilityControllerGetDeliveryDateVerbose(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilityControllerGetHolidays(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.utilityControllerGetHolidays(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityApi - object-oriented interface
 * @export
 * @class UtilityApi
 * @extends {BaseAPI}
 */
export class UtilityApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityApi
     */
    public utilityControllerGetDeliveryDate(options?: AxiosRequestConfig) {
        return UtilityApiFp(this.configuration).utilityControllerGetDeliveryDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityApi
     */
    public utilityControllerGetDeliveryDateVerbose(options?: AxiosRequestConfig) {
        return UtilityApiFp(this.configuration).utilityControllerGetDeliveryDateVerbose(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityApi
     */
    public utilityControllerGetHolidays(options?: AxiosRequestConfig) {
        return UtilityApiFp(this.configuration).utilityControllerGetHolidays(options).then((request) => request(this.axios, this.basePath));
    }
}


